import React from "react";
import "./ShelfItem.css";
import stripHtml from "../../Functions/Functions";
import { NavLink } from "react-router-dom";
import Badges from "../Badges/Badges";
import vinil from "../../img/vinil.png"
import AdultMask from "../AdultMask/AdultMask";

function ShelfItem(props){
    const isAudio = props.bookData.TYPE === "audio";
    return (
        <div className={ (isAudio ? "audioBook" : "") + " shelfItem" }>
            <NavLink to={`/book/${props.bookData.ID}`} >
                <div className="cover">
                    <img src={props.bookData.COVER200} alt={props.bookData.TITLE} />
                    <Badges bookData={props.bookData} />
                    {isAudio ? (
                        <div className="bookContainer-cover-vinil">
                            <img alt={props?.bookData?.TITLE} src={vinil} /> 
                        </div>
                    ) : null}
                    <AdultMask adultRating={props?.bookData?.ADULT} />
                </div>
                <div className="description">
                    <div className="title">
                        <h2>{props.bookData.TITLE}</h2>
                    </div>
                    <div className="annotation">
                        <p>{stripHtml(props.bookData.ANNOTATION)}</p>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

export default ShelfItem;