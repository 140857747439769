import React, { useEffect, useState } from "react";
import Button from "../../../Button/Button";
import "./ModalAuthorize.css"
import { NavLink } from "react-router-dom";
import axios from "axios";
import UrlService from "../../../../Services/UrlService";
import errorCat from "../../../../img/error.svg"

function ModalAuthorize(){

    const urlService = new UrlService();

    const [errorWindow, setErrorWindow] = useState(false);
    const [authorizationStep, setAuthorizationStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [authReqId, setAuthReqId] = useState(null);
    const [userToken, setUserToken] = useState(null);

    const phoneInput = React.createRef();
    const regularText= React.createRef();

    useEffect(() => {
        if (authorizationStep === 2){
		    startAuthorizeWithMID();
        }
    // eslint-disable-next-line
    }, [authorizationStep])

    useEffect(() => {
        if (authReqId){
            setTimeout(function(){
                checkMidProcessing();
            }, 3000);
        }
    // eslint-disable-next-line
    }, [authReqId])

    useEffect(() => {
        if (userToken){
            reloadAndLogin();
        }
    // eslint-disable-next-line
    }, [userToken])

    const checkInputKeyPressed = (e) => {
        if (e.code === 'Enter'){
            processMobilePhone();
        }
    }

    function processMobilePhone(){
		let phoneNumber = phoneInput.current.value;
		(phoneNumber[0] === "9" && phoneNumber.length === 10) && (phoneNumber = "7" + phoneNumber); 
		let expectedLength = phoneNumber[0] === "+" ? 12 : 11;
		if (phoneNumber.length !== expectedLength){
			mobilePhone_showError("Необходимо ввести корректный номер телефона")
			return false;
		}
		if (phoneNumber[0] === "+"){ phoneNumber = phoneNumber.substring(1, phoneNumber.length); }
		else if (phoneNumber[0] === "8"){ phoneNumber = "7"+phoneNumber.substring(1, phoneNumber.length); }
        setPhoneNumber(phoneNumber);
        setAuthorizationStep(2);

    }

	function mobilePhone_showError(text){
		let prevText = regularText.current.innerHTML;
		regularText.current.innerHTML = text;
		regularText.current.classList.add("errorText");
		phoneInput.current.classList.add("errorBorder");
		setTimeout(() =>{
                regularText.current.innerHTML = prevText;
				regularText.current.classList.remove("errorText");
				phoneInput.current.classList.remove("errorBorder");
        }, 3000);
	}

    function startAuthorizeWithMID(){
        // ym(53666791,'reachGoal','request_mobileID');

        const body = {
            method: "authorize", 
            msisdn : phoneNumber
        }
        const form = new FormData()
        Object.entries(body).forEach(([key, value]) => form.append(key, value))   

        axios.post(urlService.getMidUrl(), form)
        .then((data) => {
            let obj = data.data;
            if (obj.status === "success" && obj.auth_req_id){
                setAuthReqId(obj.auth_req_id);
            } else {
                setErrorWindow(true);
            }            
        })
        .catch((e) => {
            setErrorWindow(true);
        }
        );
	}

    function checkMidProcessing(){
        const body = { 
            method: "check", 
            auth_req_id : authReqId
        }
        const form = new FormData()
        Object.entries(body).forEach(([key, value]) => form.append(key, value))    

        axios.post(urlService.getMidUrl(), form)
        .then((data) => {
            let obj = data.data;
            if (obj.status === "processing"){
                setTimeout(function(){
                    checkMidProcessing();
                }, 3000)
            } else if (obj.status === "success"){
                setUserToken(obj.msisdnToken);
            }  else {
                setErrorWindow(true);
            }          
        })
        .catch((e) => {
            setErrorWindow(true);
        })
	}

	function reloadAndLogin(){
        const userData={
            "token" : userToken,
            "msisdn" : phoneNumber,
        }
        localStorage.setItem('user', JSON.stringify(userData));
        window.location.reload();		
	}



    return (
        <div className="modalAuthorization">
            { (!errorWindow && authorizationStep === 1 ) ? (
            <>
            <h2>Вход</h2>
            <p ref={regularText} className="regularText">Номер телефона</p>
            <input ref={phoneInput} className="phoneNumber" type="text" placeholder="+7 000 000-00-00" autoComplete="off" maxLength="12" onKeyDown={checkInputKeyPressed} />
            <Button onClick={processMobilePhone} buttonStyle={"w-fw"} text="Войти через Mobile ID" customGoal={"request_mobileID"} />
            <p className="smallText">При входе вы автоматически принимаете <NavLink to="/policy" target="_blank" >условия использования</NavLink> сервиса</p>
            </>
            ) : (!errorWindow && authorizationStep === 2) ? (
                <>
                    <h2>Подтвердите вход</h2>
                    <p className="waitText">Сейчас на экране вашего смартфона отобразится запрос на подтверждение входа</p>
                    <div className="bb_spinner"></div>
                </>
            ) : 
            (
                <>
                    <img alt="ошибка" src={errorCat} />
                    <h2>Что-то пошло не по плану</h2>
                    <p className="waitText">Запрос заблудился в матрице, но мы его уже ищем. Попробуйте ещё раз</p>
                </>
            )}
        </div>
    )
}

export default ModalAuthorize;