import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Preview from "../../Preview/Preview";
import "../Showcase.css"
import BookContainer from "../../BookContainer/BookContainer";
import 'animate.css'
import UrlService from "../../../Services/UrlService";

function ShowCaseAuthorBooks(props){

    const urlService = new UrlService();

    const [authorBooks, setAuthorBooks] = useState(null);
    const [apiRequestResult, setApiRequestResult] = useState(false);

    useEffect(() => {
        axios.get(
            urlService.getApiURL(), {
            params: {
                authorId: props?.authorId,
                type: "authorBooks",
                from: 0,
                col: Number(props?.count)+100
            }
        })
        .then((data) => {
            const responseData = data?.data?.data;
            if (!responseData || !responseData?.COLLECTION?.length) return false;
            let authorBooks = data?.data?.data?.COLLECTION.filter(el => el.ID !== props?.except);
            if (!authorBooks.length){ return false; }
            authorBooks = authorBooks.filter(book => book.TYPE === props.type);
            authorBooks = authorBooks.slice(0, props?.count);
            setAuthorBooks(authorBooks);
            setApiRequestResult(true);
        });
        // eslint-disable-next-line
    }, []);

    if (!apiRequestResult || !authorBooks?.length){
        return null;
    }

    const booksIterator = Array.apply(null, Array(Number(props?.count)));

    return (
        <div className="s-bg">
            <div className="showcaseRow sR-shelf">
                <div className="sR-titleRow">
                    <div className="sR-titleRow-title">
                        {authorBooks ? (<h2>{props.title}</h2>) : <Preview type={"sR-title"} />}
                    </div>
                    <div className="sR-titleRow-href">
                        {authorBooks ? <NavLink to={"/author/"+props?.authorId} className="animate__animated animate__fadeIn">все</NavLink> : "" }
                    </div>
                </div>
                <div className="sR-contentRow">
                    {booksIterator.map((el, key) => (<BookContainer key={key} bookData={authorBooks ? authorBooks[key] : null} type={props.type} />))}
                </div>
            </div>
        </div>
    )
}

export default ShowCaseAuthorBooks;