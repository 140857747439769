import React from "react";
import Showcase from "../../Components/Showcase/Showcase";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

function Books(){
    return (
        <div className="page-wrapper">
            <Header />
            <div className="page-content">
                <div className="contentWrapper">
                    <Showcase showCase={"showcase_main"}/>
                </div>
            </div>
            <Footer />
        </div>
      );    
}

export default Books;