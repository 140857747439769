import React, { useEffect } from "react";
import UrlService from "../../Services/UrlService";


function AppsPage(){

    const urlService = new UrlService();

    useEffect(() => {
        window.location.href = `${urlService.getServiceUrl()}/apps/`;
    // eslint-disable-next-line
    }, [])

    

    return (
        <></>
    )
}

export default AppsPage;