import React, {useEffect, useState } from "react";
import {useNavigate, useParams } from "react-router-dom";
import "./Author.css"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import UrlService from "../../Services/UrlService";
import { authorName } from "../../Functions/Functions";
import BookContainer from "../../Components/BookContainer/BookContainer";

function Author(props){

    const {id} = useParams();
    const authorId = id;

    const navigate = useNavigate();

    const urlService = new UrlService();

    const [authorBooks, setAuthorBooks] = useState({data: false, loaded: false});
    const [authorInfo, setAuthorInfo] = useState({data: false, loaded: false});
    const [apiRequestResult, setApiRequestResult] = useState(false);

    useEffect(() => {
        const params = {
            authorId: authorId,
            type: "authorBooks",
            col: 500
        }

        axios.get(
            urlService.getApiURL(), {
            params: params
        })
        .then((data) => {
            setApiRequestResult(true);
            if (!data?.data?.data){
                return navigate("/404");
            }

            setAuthorInfo({data: data?.data?.data?.AUTHOR, loaded: true});
            setAuthorBooks({data: data?.data?.data?.COLLECTION.sort((a,b) => b.TYPE.localeCompare(a.TYPE)), loaded: true});
            setApiRequestResult(true);
        });
    // eslint-disable-next-line
    }, [])

    if (!apiRequestResult && !authorBooks.loaded && !authorInfo.loaded){
        return null;
    }

    return (
        <>
            <div className="page-wrapper">
                <Header meta={{
                    title: `Читать книги автора ${authorName([authorInfo.data])} онлайн полностью без сокращений в приложении билайн книги`,
                }}/>
                <div className="page-content">
                    <div className="contentWrapper">
                        <div className="s-bg">
                            <div className="authorContent">
                                <h1>{authorName([authorInfo.data])} <span>{authorBooks.data.length}</span></h1>
                                <div className="authorBooks">
                                    {authorBooks.data.map((el, key) => (<BookContainer key={key} bookData={el} type={el.TYPE} />))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
                <Footer />
            </div>
        </>
    )
}

export default Author;