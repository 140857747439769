import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "./Book.css"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import UrlService from "../../Services/UrlService";
import { AppContext } from "../../Hooks/appContext";
import Button from "../../Components/Button/Button";
import stripHtml, { authorName, supportedBookFormat, transliterate } from "../../Functions/Functions";
import ShowCaseAuthorBooks from "../../Components/Showcase/Sections/ShowCaseAuthorBooks";
import SubscriptionService from "../../Services/SubscriptionService";
import greenCheck from "../../img/check_green.svg";
import freeBook from "../../img/present.svg"
import Badges from "../../Components/Badges/Badges";
import vinil from "../../img/vinil.png";
import AdultMask from "../../Components/AdultMask/AdultMask";

function Book(props){

    const {id} = useParams();
    const bookId = id.split("_").pop();
    // const [searchParams] = useSearchParams();
    // const type = searchParams.get("type");

    const { userLoggedInCtx, userTokenCtx, loadedCtx, userSubscriptionsCtx } = useContext(AppContext);

    const navigate = useNavigate();

    const urlService = new UrlService();
    const subscriptionService = new SubscriptionService();

    const [bookData, setBookData] = useState(false);
    const [apiRequestResult, setApiRequestResult] = useState(false);

    const [bookSubsInfoLoaded, setBookSubsInfoLoaded] = useState(false);

    const [bookAuthors, setBookAuthors] = useState(null);
    const [bookTranslators, setBookTranslators] = useState(null);
    const [bookReaders, setBookReaders] = useState(null);

    const [bookPrice, setBookPrice] = useState({data: false, loaded: false});
    const [bookSale, setBookSale] = useState({data: false, loaded: false});
    const [bookCanByu, setBookCanByu] = useState({data: false, loaded: false});
    const [bookCanSubscribe, setBookCanSubscribe] = useState({data: false, loaded: false});
    const [userBoughtBook, setUserBoughtBook] = useState({data: false, loaded: false});
    const [userHasSubscription, setUserHasSubscription] = useState({data: false, loaded: false});

    const [subscriptionPrice, setSubscriptionPrice] = useState(false);

    const [bookAction, setBookAction] = useState("Читайте");

    const isInitialMount = useRef(true);

    const [prevBookId, setPrevBookId] = useState(bookId);

    const [userFavBooks, setUserFavBooks] = useState({data: [], loaded: false});

    useLayoutEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (prevBookId !== id){
                loadNewBook();
            }
        }
    // eslint-disable-next-line
    }, [id]);

    useLayoutEffect(()=> {
        if (loadedCtx){
            getBookData();
        }
        // eslint-disable-next-line
    }, [loadedCtx])

    useLayoutEffect(() => {
        if (userSubscriptionsCtx){
            getBookAvailability()
        }
        getBookSubsInfo();
        // eslint-disable-next-line
    }, [userSubscriptionsCtx]);

    useLayoutEffect(() => {
        if (bookData){
            getBookAvailability()
        }
        // eslint-disable-next-line
    }, [bookData]);

    function getBookAvailability(){
        if (bookData && userSubscriptionsCtx)
        setUserHasSubscription({data: userSubscriptionsCtx.all?.isActive || (userSubscriptionsCtx.audio?.isActive && bookData.TYPE === "audio") || (userSubscriptionsCtx.books?.isActive && bookData.TYPE === "ebook"), loaded: true});
    }

    function loadNewBook(){
        setPrevBookId(bookId);
        setBookData(false);
        setApiRequestResult(false);
        setBookSubsInfoLoaded(false);
        setBookAuthors(null);
        setBookTranslators(null);
        setBookReaders(null);
        setBookPrice({data: false, loaded: false});
        setBookSale({data: false, loaded: false});
        setBookCanByu({data: false, loaded: false});
        setBookCanSubscribe({data: false, loaded: false});
        setUserBoughtBook({data: false, loaded: false});
        setSubscriptionPrice(false);
        getBookData();
        getBookSubsInfo();
    }

    function getBookData(){
        const params = {
            id: bookId,
            type: "book"
        }
        if (userLoggedInCtx && userTokenCtx){
            params.uToken = userTokenCtx;
        }
        axios.get(
            urlService.getApiURL(), {
            params: params
        })
        .then((data) => {
            setApiRequestResult(true);
            const bookData = data?.data?.data;
            if (!bookData) return navigate("/404");
            if (id !== `${transliterate(bookData.TITLE)}_${bookId}`){
                navigate(`/book/${transliterate(bookData.TITLE)}_${bookId}`, { replace: true })
            }
            setBookData(bookData);
            setBookAuthors(getbookAuthorsByType(bookData.AUTHORS, 0));
            setBookTranslators(getbookAuthorsByType(bookData.AUTHORS, 1));
            setBookReaders(getbookAuthorsByType(bookData.AUTHORS, 6));

            (Number(bookData.PAYMENT_INFO?.DISCOUNTED_PRICE) !== 0) ? setBookPrice({data: bookData.PAYMENT_INFO?.DISCOUNTED_PRICE, loaded: true}) : setBookPrice({data: bookData.PRICE, loaded: true})

            setBookSale({data: bookData.PAYMENT_INFO?.DISCOUNT, loaded: true});
            setBookCanByu({data: bookData.PAYMENT_INFO?.TRUE_PPD && bookData.PRICE >= 1, loaded: true});

            bookData.TYPE === "audio" && setBookAction("Слушайте")

        });
        setSubscriptionPrice(subscriptionService.getSubscriptionInfo("all"));
    }

    function getBookSubsInfo(){
        if (userLoggedInCtx){
            axios.get(
                urlService.getApiURL(), {
                params: {
                    type: "purchased",
                    uToken: userTokenCtx
                }
            })
            .then((purchasedData) => {
                purchasedData.data?.success ? setUserBoughtBook({data: purchasedData.data?.data.includes(Number(bookId)), loaded: true}) : setUserBoughtBook({data: false, loaded: true})
                // setUserBoughtBook({data: true, loaded: true});
            });

            getUserFavBooks();
        } else {
            setUserHasSubscription({data: false, loaded: true});
            setUserBoughtBook({data: false, loaded: true});
        }

        subscriptionService.checkBookSubs(bookId).then((bookSubs) => {
            const productRelations = bookSubs?.data?.data?.BOOK_PRODUCT_RELATIONS ? Object.entries(bookSubs.data?.data?.BOOK_PRODUCT_RELATIONS)
                .filter(el => el[1])
                .map(el => el[0]) : [];
            setBookCanSubscribe({data: productRelations?.length > 0, loaded: true});
        })
    }

    function getUserFavBooks(){
        axios.get(
            urlService.getApiURL(), {
            params: {
                type: "syncState",
                uToken: userTokenCtx
            }
        })
        .then((resp) => {
            if (!resp.data?.success){
                setUserFavBooks({data: [], loaded: true});
                return false;
            }
            const favBooks = resp?.data?.data.filter(book => book.status === "READ").sort(function(a,b){
                return b.status_update_date - a.status_update_date;
            });
            setUserFavBooks({data: favBooks, loaded: true})
        }); 
    }

    useLayoutEffect(() => {
        setBookSubsInfoLoaded(
            bookPrice.loaded &&
            bookSale.loaded &&
            bookCanByu.loaded &&
            bookCanSubscribe.loaded &&
            userBoughtBook.loaded &&
            userHasSubscription.loaded
        )
    }, [bookPrice, bookSale, bookCanByu, bookCanSubscribe, userBoughtBook, userHasSubscription])

    function getbookAuthorsByType(allAuthors, relation){
        return allAuthors.filter(author => Number(author.RELATION) === Number(relation));
    }

    function saveBook(){
        let bookObj = {
            "book_id": Number(bookId),
            "extension": bookData?.AVALIABLE_FORMATS[0] || "",
            "status": "READ",
            "status_update_date": Date.now(),
            "total_chars": 0,
            "user_device": "WEB",
            "created_date": Date.now()
        }
        axios.get(
            urlService.getApiURL(), {
            params: {
                type: "syncEvent",
                uToken: userTokenCtx,
                ...bookObj,
            }
        })
        .then((resp) => {
            getUserFavBooks()
        });
    }

    function deleteBook(){
        let bookObj = userFavBooks?.data?.filter(book => book.book_id === Number(bookId));
        axios.get(
            urlService.getApiURL(), {
            params: {
                type: "syncEvent",
                uToken: userTokenCtx,
                ...bookObj[0],
                status: "DELETED",
                status_update_date: Date.now(),
                user_device: "WEB",
            }
        })
        .then((resp) => {
            getUserFavBooks()
        });
    }    

    if (apiRequestResult && !bookData){
        return null;
    }

    if (["WITHDRAWN", "UNAVAILABLE"].includes(bookData.IS_AVAILABLE)){
        return navigate("/404");
    }

    return (
        <>
            <div className="page-wrapper">
                <Header meta={{
                    title: `«${bookData?.TITLE}» - ${authorName(bookData?.AUTHORS)} читать онлайн полностью без сокращений в приложении билайн книги`,
                    description: stripHtml(bookData?.ANNOTATION)?.substring(0, 320),
                }}/>
                <div className="page-content">
                    <div className="contentWrapper">
                        <div className="s-bg">
                            <div className="bookContent">
                                <div className="topLine">
                                    <div className="descriptionColumn">
                                        <p className="bookType">
                                            {bookData.TYPE === "audio" ? "аудиокнига" : "книга"}
                                        </p>
                                        <h1 itemProp="name">{bookData.TITLE}</h1>
                                        { bookAuthors?.length ? (
                                        <div className="contentLine">
                                            Автор: {bookAuthors?.map((author, key) => { return (
                                                        <NavLink itemProp="author" key={key} to={"/author/"+author.ID}>{(author.FIRST_NAME ? author.FIRST_NAME+" " : "") + "" + (author.LAST_NAME ? author.LAST_NAME : "")}{(key < (bookAuthors.length-1)) ? ", " : "" }</NavLink>
                                                )}
                                            )}
                                        </div>
                                        ) : null }
                                        { bookTranslators?.length ? (
                                        <div className="contentLine">
                                            Перевод: {bookTranslators?.map((author, key) => { return (
                                                    <NavLink key={key} to={"/author/"+author.ID}>{(author.FIRST_NAME ? author.FIRST_NAME+" " : "") + "" + (author.LAST_NAME ? author.LAST_NAME : "")}{(key < (bookTranslators.length-1)) ? ", " : "" }</NavLink>
                                                )}
                                            )}
                                        </div>
                                        ) : null }
                                        { bookReaders?.length ? (
                                        <div className="contentLine">
                                            Чтец: {bookReaders?.map((author, key) => { return (
                                                    <NavLink key={key} to={"/author/"+author.ID}>{(author.FIRST_NAME ? author.FIRST_NAME+" " : "") + "" + (author.LAST_NAME ? author.LAST_NAME : "")}{(key < (bookReaders.length-1)) ? ", " : "" }</NavLink>
                                                )}
                                            )}
                                        </div>
                                        ) : null }
                                        { bookData?.ADULT ? (
                                        <div className="contentLine adult">
                                            Возрастное ограничение: <span>{bookData?.ADULT}+</span>
                                        </div>
                                        ) : null }
                                        <div className="categories">
                                            {bookData?.CATEGORIES?.map((cat, key) => {
                                                // return (<div key={key} className="category"><NavLink to={"#"}>{cat}</NavLink></div>)
                                                return (<div key={key} className="category">{cat}</div>)
                                            })}
                                        </div>
                                    </div>
                                    <div className="coverColumn">
                                        <div className="cover">
                                            { bookData ? (
                                                <>
                                                    <img itemProp="image" src={bookData.COVER} alt={bookData.TITLE} />
                                                    <AdultMask adultRating={bookData?.bookData?.ADULT} />
                                                    <Badges bookData={bookData} big={true} />
                                                    { bookData?.TYPE === "audio" ? (
                                                        <div className="bookContainer-cover-vinil">
                                                            <img alt={bookData?.bookData?.TITLE} src={vinil} /> 
                                                        </div>
                                                    ) : null }
                                                </>
                                            ) : null }
                                        </div>
                                        <div className="buttons">
                                            <div className="buttonsLine">
                                                {userLoggedInCtx ? (
                                                    <>
                                                        {bookData.TYPE === "audio" ? (
                                                            <Button action={"modal"} modalSrc={"downloadApp"} text="Слушать" buttonStyle={"ptb-15 hoverYellow"} customGoal={"button_listen_app"} customTitle="Слушайте в приложении" customQr={urlService.getQrCodeLink(`book/${bookId}`)} />
                                                        ) : 
                                                            // <Button action={""} text="Читать" buttonStyle={"ptb-15 hoverYellow"} customGoal={"button_read_book"} customTitle="Читайте в приложении" customQr={urlService.getQrCodeLink(`book/${bookId}`)} />
                                                            // <Button action={"modal"} modalSrc={"downloadApp"} text="Читать" buttonStyle={"ptb-15 hoverYellow"} customGoal={"button_read_book"} customTitle="Читайте в приложении" customQr={urlService.getQrCodeLink(`book/${bookId}`)} />
                                                            (!bookCanSubscribe.data && !userBoughtBook.data) ? (
                                                                <Button action={"modal"} modalSrc={"downloadApp"} text="Читать" buttonStyle={"ptb-15 hoverYellow"} customGoal={"button_read_book"} customTitle="Читайте в приложении" customQr={urlService.getQrCodeLink(`book/${bookId}`)} />
                                                            ) : supportedBookFormat(bookData?.AVALIABLE_FORMATS) ? (
                                                                <Button action="url" text="Читать" buttonStyle={"ptb-15 hoverYellow"} blank={true} href={`${urlService.getReaderUrl(bookData.ID)}`} customGoal={"button_read_book"} />  
                                                            ) : (
                                                                <Button action={"modal"} modalSrc={"downloadApp"} text="Читать" buttonStyle={"ptb-15 hoverYellow"} customGoal={"button_read_book"} customTitle="Читайте в приложении" customQr={urlService.getQrCodeLink(`book/${bookId}`)} />
                                                            )
                                                        }
                                                        {userFavBooks.loaded && userFavBooks.data?.filter(book => book.book_id === Number(bookId)).length ? (
                                                            <Button text="✓ Отложено" buttonStyle={"grey ptb-15 hoverGrey"} customGoal={"button_save_book"} onClick={() => deleteBook()} />                                            
                                                        ) : (
                                                            <Button text="Отложить" buttonStyle={"grey ptb-15 hoverGrey"} customGoal={"button_save_book"} onClick={() => saveBook()} />                                            
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {bookData.TYPE === "audio" ? (
                                                            <Button action={"modal"} modalSrc={"downloadApp"} text="Слушать" buttonStyle={"ptb-15 hoverYellow"} customGoal={"button_listen_app"} customTitle="Слушайте в приложении" customQr={urlService.getQrCodeLink(`book/${bookId}`)} />
                                                            // <Button action="modal" modalSrc="authorizationPanel" text="Слушать" buttonStyle="ptb-15 hoverYellow" customGoal={"button_listen_app"}  customQr={`${urlService.getProdUrl()}/book/${bookId}`}/>
                                                        ) : (
                                                            // <Button action={"modal"} modalSrc={"downloadApp"} text="Читать" buttonStyle={"ptb-15 hoverYellow"} customGoal={"button_read_book"} customTitle="Читайте в приложении" customQr={urlService.getQrCodeLink(`book/${bookId}`)} />
                                                            <Button action="modal" modalSrc="authorizationPanel" text="Читать" buttonStyle="ptb-15 hoverYellow" customGoal={"button_read_book"} />
                                                        )}
                                                        <Button action="modal" modalSrc="authorizationPanel" text="Отложить" buttonStyle="grey ptb-15 hoverGrey" customGoal={"button_save_book"} />
                                                    </>
                                                ) }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="subsColumn">
                                        <div className="wrapper">
                                            { bookData?.IS_FREE ? (
                                                    <div className="result">
                                                        <div className="firstLine">
                                                            <div className="leftText">
                                                                Бесплатная книга
                                                            </div>
                                                            <div className="rightCheck">
                                                                <img src={freeBook} alt="бесплатная книга" />                                                                  
                                                            </div>
                                                        </div>
                                                        <div className="secondLine">
                                                            Книга доступна вам без ограничений. Попробуйте наш сервис бесплатно
                                                        </div>
                                                    </div>
                                                ) : bookSubsInfoLoaded ? (
                                                    (!((userHasSubscription.data && bookCanSubscribe.data) || (userBoughtBook.data))) ? (
                                                        <>
                                                            { bookCanSubscribe.data ? (
                                                                userLoggedInCtx ? (
                                                                    bookData?.IS_EXCLUSIVE ? (
                                                                        <Button action="modal" modalSrc="subscriptionPanel" text="Подписаться<br/>30 дней бесплатно" buttonStyle="ptb-15 w-fw bp-nb" additData={{type:subscriptionPrice.name}} customGoal={"subscribe_all"} />
                                                                    ) : <Button action="modal" modalSrc="subscriptionPanel" text="Подписаться" buttonStyle={`withPrice hoverYellow ptb-10 w-fw ${userSubscriptionsCtx?.extra?.tarifUp ? "upColor" : ""}`} additData={{price: userSubscriptionsCtx?.extra?.tarifUp ? subscriptionPrice.prices.up : subscriptionPrice.prices.m, type:subscriptionPrice.name}} />
                                                                ) : (
                                                                    bookData?.IS_EXCLUSIVE ? (
                                                                        <Button action="modal" modalSrc="authorizationPanel" text="Подписаться<br/>30 дней бесплатно" buttonStyle="withPrice hoverYellow ptb-10 w-fw" additData={{price:subscriptionPrice.prices.m}} />
                                                                    ) : <Button action="modal" modalSrc="authorizationPanel" text="Подписаться" buttonStyle="withPrice hoverYellow ptb-10 w-fw" additData={{price:subscriptionPrice.prices.m}} />
                                                                )
                                                            ) : null }
                                                            { (bookData.PRICE > 0 && bookCanByu.data) ? (
                                                                userLoggedInCtx ? (
                                                                    <Button action="url" href={`/purchase/${bookData.ID}`} text="Купить книгу" buttonStyle={`withPrice grey hoverGrey ptb-10 w-fw ${bookSale.data ? "saleButton" : ""}`} additData={{price:bookPrice.data}} />
                                                                ) : (
                                                                    <Button action="modal" modalSrc="authorizationPanel" text="Купить книгу" buttonStyle={`withPrice grey hoverGrey ptb-10 w-fw ${bookSale.data ? "saleButton" : ""}`} additData={{price:bookPrice.data}} />
                                                                )
                                                            ) : null }
                                                            { (bookCanByu.data && bookCanSubscribe.data) ? (
                                                                <>
                                                                    <p className="mainText">{bookAction} книгу по подписке или купите</p>
                                                                    <p className="smallText">С подпиской вы получите доступ к этой и тысячам других книг</p>
                                                                </>
                                                            ) : (bookCanByu.data && !bookCanSubscribe.data && userHasSubscription.data) ? (
                                                                <>
                                                                    <p className="mainText">Купите и {bookAction.toLowerCase()} когда угодно</p>
                                                                    <p className="smallText">Книга недоступна по подписке</p>
                                                                </>
                                                            ) : (bookCanByu.data && !bookCanSubscribe.data && !userHasSubscription.data) ? (
                                                                <>
                                                                    <p className="mainText">Купите и {bookAction.toLowerCase()} когда угодно</p>
                                                                    <p className="smallText">У вас будет доступ к книге, даже без подписки</p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p className="mainText">{bookAction} книгу по подписке </p>
                                                                    <p className="smallText">С подпиской вы получите доступ к этой и тысячам других книг</p>
                                                                </>
                                                            ) }
                                                            {bookSale.data ? (
                                                                <div className="discountBadge">
                                                                    <div className="content">
                                                                        <span>{bookSale.data}%</span><br/>скидка<br/>на&nbsp;покупку
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                (userSubscriptionsCtx?.extra?.tarifUp && (!bookCanByu?.data && bookCanSubscribe?.data)) ? (
                                                                    <div className="upBadge">
                                                                        <div className="content">
                                                                            <span>100Р</span><br/>в&nbsp;месяц&nbsp;с<br/>тарифом&nbsp;UP
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="result">
                                                            <div className="firstLine">
                                                                <div className="leftText">
                                                                    {userBoughtBook.data ? (
                                                                        "Книга куплена"
                                                                    ) : (
                                                                        "Книга в подписке"
                                                                    )}
                                                                </div>
                                                                <div className="rightCheck">
                                                                    <img src={greenCheck} alt="книга доступна" />                                                                  
                                                                </div>
                                                            </div>
                                                            <div className="secondLine">
                                                                {userBoughtBook.data ? (
                                                                    "Книга доступна вам без ограничений. Ваш доступ к книге останется, даже если она пропадет из каталога"
                                                                ) : (
                                                                    "Книга доступна вам без ограничений, пока ваша подписка активна и книга есть в каталоге сервиса"
                                                                )}  
                                                            </div>
                                                        </div>
                                                    )
                                            ) : (
                                                <div className="bb_spinner medium"></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="middleLine">
                                    <h2>О книге</h2>
                                    <div className="description" itemProp="description">
                                        {stripHtml(bookData.ANNOTATION)}
                                    </div>
                                </div>
                                {bookAuthors ? (
                                    <div className="bottomLine">
                                        <ShowCaseAuthorBooks title="Другие книги автора" count="6" authorId={bookAuthors[0]?.ID} type="ebook" except={bookId} />
                                        <ShowCaseAuthorBooks title="Аудиокниги автора" count="6" authorId={bookAuthors[0]?.ID} type="audio" except={bookId} />
                                    </div>
                                ) : null }
                            </div>
                        </div>
                    </div>
                </div>            
                <Footer />
            </div>
        </>
    )
}

export default Book;