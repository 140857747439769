import React, {useState, useEffect, useContext } from "react";
import "./AgeNotification.css"
import Button from "../../Button/Button";
import { AppContext } from "../../../Hooks/appContext";

function AgeNotification(){

    const [ageNotifyer, setAgeNotifyer] = useState(false);

    const {adultBannerShownCtx, toggleUserBlockedAdultCtx} = useContext(AppContext);


    useEffect(() => {
        if (adultBannerShownCtx === false && localStorage.getItem('bbooks_blockedAdult') === "0"){
            setAgeNotifyer(false);
        }            
    }, [adultBannerShownCtx])


    useEffect(() => {
        if (
            (!localStorage.hasOwnProperty('bbooks_adultNotifyerShown') && localStorage.getItem('bbooks_blockedAdult') !== 1)
            || (!localStorage.hasOwnProperty('bbooks_adultNotifyerShown') && !localStorage.hasOwnProperty('bbooks_blockedAdult')) 
            || (localStorage.hasOwnProperty('bbooks_adultNotifyerShown') && +localStorage.getItem('bbooks_adultNotifyerShown') === 0) 
        ){
            setAgeNotifyer(true);
        }
    }, []);

    function CloseNotification(accepted){
        if (accepted){
            localStorage.setItem('bbooks_adultNotifyerShown', 1);
            localStorage.setItem('bbooks_blockedAdult', 0);
            toggleUserBlockedAdultCtx()
        } else {
            localStorage.setItem('bbooks_adultNotifyerShown', 1);
        }
        setAgeNotifyer(false);
    }

    if (!ageNotifyer){
        return null;
    }

    return (
        <div className="ageNotificationPanel">
            <p>Сервис имеет возрастные ограничения</p>
            <p className="smallText">Вам уже есть 18 лет?</p>
            <Button text="Да, мне есть 18 лет" buttonStyle="thin w-fw ptb-20" onClick={() => CloseNotification(true)}/>
            <Button text="Нет, я ребенок"  buttonStyle="thin lightGrey w-fw ptb-20"  onClick={() => CloseNotification(false)}/>
        </div>
    )
}

export default AgeNotification;