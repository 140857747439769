import React from "react";
import "./Badges.css";
import { PiCrownSimple } from "react-icons/pi";
import { IoGiftOutline } from "react-icons/io5";

function SaleBadge(props){
    return (
        <div className="badges badges-sale">скидка {props.saleSize} %</div>
    )
}

function ExclusiveBadge(){
    return (
        <div className="badges badges-exclusive">эксклюзив <PiCrownSimple /></div>
    )
}

function FreeBadge(){
    return (
        <div className="badges badges-free">бесплатно <IoGiftOutline /></div>
    )
}

function Badges(props){

    const isExclusive = props?.bookData?.IS_EXCLUSIVE;
    const isSale = props?.bookData?.PAYMENT_INFO?.DISCOUNT;
    const isFree = props?.bookData?.IS_FREE;

    // const isExclusive = true;
    // const isSale = 20;

    if (!isExclusive && !isSale && !isFree){
        return null;
    }

    return (
        <div className={props?.big ? "badgesArea bigBadges" : "badgesArea"} >
            {(isSale && !props?.big) ? <SaleBadge saleSize={isSale} /> : null}
            {isExclusive ? <ExclusiveBadge /> : null}
            {isFree ? <FreeBadge /> : null}
        </div>
    )
}

export default Badges