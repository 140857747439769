import React, { useEffect, useState } from "react";
import "./TopButton.css"
import topButton from "../../img/up.svg"

function TopButton(){

    const [buttonShown, setButtonShown] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", trackScroll);
    }, [])

    function trackScroll() {
        const scrolled = window.scrollY;
        const coords = document.documentElement.clientHeight;
        if (scrolled > coords) {
            setButtonShown(true);
        } else {
            setButtonShown(false);
        }
    }    

    function scrollTop(){
        if (window.scrollY > 0) {
            window.scrollTo(0, 0)
        }
    }

    return (
        buttonShown ? <div className="c-topButton" onClick={() => scrollTop() }>
            <img alt="наверх" src={topButton} />
        </div> : null
    )
}

export default TopButton;