import React from "react";
import "./ButtonSecondary.css";
import { NavLink } from "react-router-dom";

function ButtonSecondary(props){

    if (!props?.text){
        return null;
    }

    if (props?.action === "url"){
        return (
            <React.Fragment>
                <NavLink to={props?.href} >
                    <div className="buttonSecondary">
                        {props?.text}
                    </div>
                </NavLink>
            </React.Fragment>
        )        
    }

    return (
        <React.Fragment>
            <div className="buttonSecondary">
                {props?.text}
            </div>
        </React.Fragment>
    )
    
}

export default ButtonSecondary;