// import { createContext } from "react";

// export const AppContext = createContext({
//     userLoggedInCtx: false,
//     userTokenCtx: null,
//     userMsisdnCtx: null,
//     userImageCtx: null,
//     userIsBeeCtx: null,
//     userSubscriptionsCtx: null,
//     userBlockedAdultCtx: null,
// });

import React, { createContext, useState } from 'react';

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const [userLoggedInCtx, setUserLoggedInCtx] = useState(false);
  const [userTokenCtx, setUserTokenCtx] = useState(null);
  const [userMsisdnCtx, setUserMsisdnCtx] = useState(null);
  const [userImageCtx, setUserImageCtx] = useState(null);
  const [userIsBeeCtx, setUserIsBeeCtx] = useState(null);
  const [userSubscriptionsCtx, setUserSubscriptionsCtx] = useState(null);
  const [userBlockedAdultCtx, setUserBlockedAdultCtx] = useState(null);
  const loadedCtx = true;
  const [adultBannerShownCtx, setAdultBannerShownCtx] = useState(false);

  const toggleUserBlockedAdultCtx = () => {
    setUserBlockedAdultCtx(!userBlockedAdultCtx);
  };

  const showAdultBannerCtx = (val) => {
    setAdultBannerShownCtx(val);
  }

  return (
    <AppContext.Provider
      value={{
        userLoggedInCtx,
        setUserLoggedInCtx,
        userTokenCtx,
        setUserTokenCtx,
        userMsisdnCtx,
        setUserMsisdnCtx,
        userImageCtx,
        setUserImageCtx,
        userIsBeeCtx,
        setUserIsBeeCtx,
        userSubscriptionsCtx,
        setUserSubscriptionsCtx,
        userBlockedAdultCtx,
        toggleUserBlockedAdultCtx,
        loadedCtx,
        adultBannerShownCtx,
        setAdultBannerShownCtx,
        showAdultBannerCtx
      }}
    >
      {children}
    </AppContext.Provider>
  );
};