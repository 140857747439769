import React from "react";
import { NavLink } from "react-router-dom";

function ShowCaseShowcases(props){

    const showcases = props?.content?.showcases;
    // const showcasesIterator = Array.apply(null, Array(showcases?.length));
    const showcasesIterator = [];

    showcases?.forEach(showcase => {

        let style = {
            backgroundColor: showcase.style.backgroundColor,
            backgroundImage: `url(${showcase.style.webImageUrl})`,
            backgroundSize: "contain",
            backgroundPosition: "center right",
        }

        let showCaseName = showcase?.remoteConfigName?.split("_")[1];
        let link = `/books/${showCaseName ? showCaseName : ""}`;

        showcasesIterator.push({title: showcase.title, style:style, link:link});
    });

    if (!showcasesIterator.length){
        return null;
    }

    return (
        <div className="s-bg">
            <div className="showcaseRow sR-showcases">
                <div className="sR-contentRow">
                    {showcasesIterator.map((el, key) => (
                        <NavLink key={key} to={el.link} >
                            <div className="sR-showcases-item" style={el.style}>
                                <div className="sR-showcases-title">
                                    {el.title}
                                </div>
                            </div>
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ShowCaseShowcases;