import React from "react";
import Showcase from "../../Components/Showcase/Showcase";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

function Audio(){
    return (
        <div className="page-wrapper">
            <Header meta={{
                title:"Слушать популярные книги в электронной библиотеке | билайн книги"
            }} />
            <div className="page-content">
                <div className="contentWrapper">
                    <Showcase showCase={"showcase_audio"}/>
                </div>
            </div>
            <Footer />
        </div>
    );    
}

export default Audio;