import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Preview from "../../Preview/Preview";
import "../Showcase.css"
import BookContainer from "../../BookContainer/BookContainer";
import 'animate.css'
import coverPreview from "../../../img/bookPreloader.svg"
import ButtonSecondary from "../../ButtonSecondary/ButtonSecondary";
import stripHtml, { authorName } from "../../../Functions/Functions.js";
import UrlService from "../../../Services/UrlService.js";
import Badges from "../../Badges/Badges.js";
import AdultMask from "../../AdultMask/AdultMask.js";

function ShowCaseShelfBig(props){

    const urlService = new UrlService();

    const [shelfData, setShelfData] = useState(null);
    const [apiRequestResult, setApiRequestResult] = useState(false);

    useEffect(() => {
        axios.get(
            urlService.getApiURL(), {
            params: {
                sId: props?.content?.identifiers[0],
                type: "shelf"
            }
        })
        .then((data) => {
            setApiRequestResult(true);
            const responseData = data?.data?.data;
            if (!responseData || !responseData[0]?.BOOKS?.length) return false
            let shelfData = data?.data?.data[0];
            shelfData.BOOKS = shelfData?.BOOKS?.sort((a, b) => 0.5 - Math.random())
            setShelfData(shelfData);
        });
        // eslint-disable-next-line
    }, []);

    if (apiRequestResult && !shelfData?.BOOKS){
        return null;
    } 

    const shelvesIterator = Array.apply(null, Array(props?.count));

    return (
        <div className="s-bg">
            <div className="showcaseRow sR-shelfBig">
                <div className="sR-titleRow">
                    <div className="sR-titleRow-title">
                        {shelfData ? (<h2>{shelfData.TITLE}</h2>) : <Preview type={"sR-title"} />}
                    </div>
                    <div className="sR-titleRow-href">
                        {shelfData ? <div className="animate__animated animate__fadeIn"><NavLink to={"/shelf/"+props?.content?.identifiers[0]}>все</NavLink></div> : "" }
                    </div>
                </div>
                <div className="sR-contentRow">
                    <div className="sR-contentRow-left">
                        <NavLink to={"/book/"+shelfData?.BOOKS[0].ID}>
                            {shelfData?.BOOKS[0]?.COVER ? <img className="animate__animated animate__fadeIn" src={shelfData?.BOOKS[0]?.COVER} alt={shelfData?.BOOKS[0]?.TITLE}/> : <img className="coverPreview" src={coverPreview} alt=""/>}
                        </NavLink>
                        <AdultMask adultRating={shelfData?.BOOKS[0]?.ADULT}/>
                        <Badges bookData={shelfData?.BOOKS[0]} big={true} />
                    </div>
                    <div className="sR-contentRow-right">
                        {shelvesIterator.map((el, key) => (<BookContainer key={key} bookData={shelfData ? shelfData.BOOKS[key+1] : null} />))}
                    </div>
                </div>
                <div className="sR-additContentRow">
                    <div className="sR-additContentWrapper">
                        <div className="sR-additContent-titleRow">
                            <div className="sR-additContent-titleRow-left">
                                <div className="sR-additContent-titleRow-title">
                                    {shelfData?.BOOKS[0]?.TITLE ? shelfData?.BOOKS[0]?.TITLE : <Preview type={"sR-bookTitle"} />}
                                </div>
                                <div className="sR-additContent-titleRow-author">
                                    {shelfData?.BOOKS[0]?.AUTHORS?.length ? authorName(shelfData?.BOOKS[0]?.AUTHORS) : <Preview type={"sR-bookAuthor"} />}
                                </div>
                            </div>
                            <div className="sR-additContent-titleRow-right">
                                <ButtonSecondary text={"подробнее"} action={"url"} href={"/book/"+shelfData?.BOOKS[0].ID} />
                            </div>
                        </div>
                        <div className="sR-additContent-contentRow">
                            {shelfData?.BOOKS[0] ? stripHtml(shelfData?.BOOKS[0]?.ANNOTATION) : <Preview type={"sR-bookTitle"} />}
                        </div>
                        <div className="sR-additContentRow-tail"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowCaseShelfBig;