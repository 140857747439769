import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import SwiperMainBanner from "../../Components/Swiper/SwiperMainBanner/SwiperMainBanner";
import ShowCaseShelf from "../../Components/Showcase/Sections/ShowCaseShelf";
import Showcase from "../../Components/Showcase/Showcase";

function Main(){

    return (
        <div className="page-wrapper">
            <Header />
            <div className="page-content">
                <SwiperMainBanner />
                <div className="contentWrapper">
                    <ShowCaseShelf content={{identifiers : [356424639]}} count={3} customUrl={"/books"} customGoal={"button_vse_knigi"} booksStyle={"rotation"} />
                        {/* <ShowCaseShelfAudio content={{identifiers : [28504409]}} count={5} customUrl={"/audio"} customGoal={"button_vse_audio"} /> */}
                    <Showcase showCase={`web_main_page`}/>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Main;