import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "./Purchase.css"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import UrlService from "../../Services/UrlService";
import { AppContext } from "../../Hooks/appContext";
import Button from "../../Components/Button/Button";
import SubscriptionService from "../../Services/SubscriptionService";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";
import { GoCreditCard } from "react-icons/go";
import { CiWarning } from "react-icons/ci";
import qrPurchase from "../../img/qrPurchase.png"
import AppBadge from "../../Components/AppBadge/AppBadge";
import stripHtml, { authorName } from "../../Functions/Functions";


function Purchase(props){

    const {id} = useParams();
    const bookId = id;

    const { userLoggedInCtx, userTokenCtx, loadedCtx } = useContext(AppContext);
    const urlService = new UrlService();
    const subscriptionService = new SubscriptionService();
    const [bookData, setBookData] = useState(false);
    const [apiRequestResult, setApiRequestResult] = useState(false);
    const [bookAuthors, setBookAuthors] = useState(null);
    const [bookPrice, setBookPrice] = useState({data: false, loaded: false});
    const [bookCanByu, setBookCanByu] = useState({data: false, loaded: false});
    const [purchaseStatus, setPurchaseStatus] = useState({code: 0, error: false}); // 0 - ждем информацию, 100 - не куплена, 101 - в процессе, 102 - куплена, 500 - ошибка
    const [userLoggedInLoaded, setUserLoggedInLoaded] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState("#");

    const navigate = useNavigate();

    useEffect(() => {
        if (userLoggedInLoaded && !userLoggedInCtx){
            navigate('/')
        }
    }, [userLoggedInLoaded, userLoggedInCtx, navigate])

    useLayoutEffect(()=> {
        if (loadedCtx){
            const params = {
                id: bookId,
                type: "book"
            }
            if (userLoggedInCtx && userTokenCtx){
                params.uToken = userTokenCtx;
            }
            axios.get(
                urlService.getApiURL(), {
                params: params
            })
            .then((data) => {
                setApiRequestResult(true);
                const bookData = data?.data?.data;
                if (!bookData) return false
                setBookData(bookData);
                setBookAuthors(getbookAuthorsByType(bookData.AUTHORS, 0));
                (Number(bookData.PAYMENT_INFO?.DISCOUNTED_PRICE) !== 0) ? setBookPrice({data: bookData.PRICE, loaded: true}) : setBookPrice({data: bookData.PRICE, loaded: true})
                setBookCanByu({data: bookData.PAYMENT_INFO?.TRUE_PPD && bookData.PRICE >= 1, loaded: true});
            });
        }
        // eslint-disable-next-line
    }, [loadedCtx])

    useLayoutEffect(() => {
        setTimeout(() => {
            setUserLoggedInLoaded(true);
        }, 0)
    }, [userLoggedInCtx]);    

    useLayoutEffect(() => {
        if (userLoggedInCtx && bookPrice.loaded && bookCanByu.loaded){
            subscriptionService.checkPurchaseStatus({ bId: bookId, uToken: userTokenCtx })
            .then((data) => {
                const paymentState = data?.data?.data?.PAYMENT_STATE;
                if (paymentState === "INITIAL"){
                    purchaseBook();
                } else if (paymentState === "PAYED"){
                    setPurchaseStatus({code: 102, error: false});
                    syncBook();
                } else if (paymentState === "NOT_EXISTS"){
                    setPurchaseStatus({code: 100, error: false});
                }
            })
            .catch(e => {
                setPurchaseStatus({code: 500, error: true});
            });
        }
        // eslint-disable-next-line
    }, [bookPrice, bookCanByu, userLoggedInCtx])  

    function getbookAuthorsByType(allAuthors, relation){
        return allAuthors.filter(author => Number(author.RELATION) === Number(relation));
    }

    function purchaseBook(){
        subscriptionService.purchaseBook({ bId: bookId, uToken: userTokenCtx })
        .then((data) => {
            const paymentUrl = data?.data?.data?.PAYMENT_URL;
            if (paymentUrl !== ''){
                window.open(paymentUrl, '_blank').focus();
                setPaymentUrl(paymentUrl);
                setPurchaseStatus({code: 101, error: false});
                checkPurchasingStatus();
            } else {
                setPurchaseStatus({code: 500, error: true});
            }
        })
        .catch(e => {
            setPurchaseStatus({code: 500, error: true});
        })
    }

    function checkPurchasingStatus(){
        subscriptionService.checkPurchaseStatus({ bId: bookId, uToken: userTokenCtx })
        .then((data) => {
            const paymentState = data?.data?.data?.PAYMENT_STATE;
            if (paymentState === "INITIAL"){
                setTimeout(() => {
					checkPurchasingStatus();
				}, "10000");  
            } else if (paymentState === "PAYED"){
                setPurchaseStatus({code: 102, error: false});
                syncBook();
            } else {
                setPurchaseStatus({code: 500, error: true});
            }
        })
        .catch(e => {
            setPurchaseStatus({code: 500, error: true});
        })        
    }

    function syncBook(){
        let bookObj = {
            "book_id": Number(bookId),
            "extension": bookData?.AVALIABLE_FORMATS[0] || "",
            "status": "READ",
            "status_update_date": Date.now(),
            "total_chars": 0,
            "user_device": "WEB",
            "created_date": Date.now()
        }
        axios.get(
            urlService.getApiURL(), {
            params: {
                type: "syncEvent",
                uToken: userTokenCtx,
                ...bookObj,
            }
        });        
    }

    function errorData(code){
        if (code === 100){
            return {
                title: "Ошибка оплаты",
                text: "Проверьте наличие средств на счете и повторите попытку."
            }
        } 

        return {
            title: "Что-то пошло не так",
            text: "Запрос заблудился в сети. Проверьте списание с вашей банковской карты. Если оплата не прошла, проверьте введенные данные карты и попробуйте еще раз"
        }
    }

    if (apiRequestResult && !bookData){
        return null;
    }

    if (["WITHDRAWN", "UNAVAILABLE"].includes(bookData.IS_AVAILABLE)){
        return navigate("/404");
    }

    return (
        <div className="page-wrapper">
            <Header meta={{
                title: `«${bookData?.TITLE}» - ${authorName(bookData?.AUTHORS)} читать онлайн полностью без сокращений в приложении билайн книги`,
                description: stripHtml(bookData?.ANNOTATION)?.substring(0, 320),
            }}/>
            <div className="page-content">
                <div className="contentWrapper">
                    <div className="s-bg">
                        <div className="purchaseContent">
                            <div className="topLine">
                                <NavLink to={`/book/${bookData?.ID}`}>
                                    <IoChevronBackOutline /> Назад
                                </NavLink>
                            </div>
                            <div className="contentLine">
                                <div className="leftPart">
                                    <div>
                                        { purchaseStatus.code === 102 ? (
                                            <h1>Поздравляем! {bookData.TYPE === "audio" ? "Аудиокнига" : "Книга"} теперь ваша</h1>
                                        ) : (
                                            <h1>Покупка {bookData.TYPE === "audio" ? "аудиокниги" : "книги"}</h1>
                                        )}
                                    </div>
                                    <div className="bookInfo">
                                        <div className={`bookCover ${bookData.TYPE === "audio" ? " audio" : ""}`}>
                                            <img src={bookData.COVER} alt={bookData.TITLE} />
                                        </div>
                                        <div className={`bookDescription ${bookData.TYPE === "audio" ? " audio" : ""}`}>
                                            <NavLink to={`/book/${bookData?.ID}`}>
                                                <div className="info">
                                                    <h2>{bookData.TITLE}</h2>
                                                    { bookAuthors?.length ? (
                                                        <p>
                                                            {bookAuthors?.map((author, key) => { return (
                                                                    <span key={key}>{(author.FIRST_NAME ? author.FIRST_NAME+" " : "") + "" + (author.LAST_NAME ? author.LAST_NAME : "")}{(key < (bookAuthors.length-1)) ? ", " : "" }</span>
                                                                )}
                                                            )}
                                                        </p>
                                                    ) : null }    
                                                </div>
                                                <div className="navArrow">
                                                    <IoChevronForward />
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="rightPart">
                                    <div className="statusWrapper">
                                        { purchaseStatus.error ? (
                                            <div className="errorState">
                                                <div className="topLine">
                                                    <h3>{errorData(purchaseStatus.code).title}</h3><CiWarning />
                                                </div>
                                                <p>{errorData(purchaseStatus.code).text}</p>
                                            </div>                                            
                                        ) : purchaseStatus.code === 100 ? (
                                            <div className="defaultState">
                                                <h3>Способ оплаты</h3>
                                                <div className="paymentType">
                                                    <GoCreditCard />
                                                    <div>Банковской картой</div>
                                                </div>
                                                <div className="rules">
                                                    <p>Условия предоставления услуги</p>
                                                    <ul>
                                                        <li>Вы получите неограниченный доступ к книге в сервисе книги билайн</li>
                                                        <li>Книга будет доступна вам онлайн и офлайн в приложении</li>
                                                        <li>Данный товар возврату не подлежит</li>
                                                    </ul>
                                                </div>
                                                <div className="price">
                                                    {bookPrice.data} ₽
                                                </div>
                                                <Button onClick={() => purchaseBook()} text="Оплатить" buttonStyle="w-fw" />
                                            </div>
                                        ) : purchaseStatus.code === 101 ? (
                                            <div className="processingState">
                                                <div className="topLine">
                                                    <h3>Платёж в обработке</h3><CiWarning />
                                                </div>
                                                <p>Ожидаем ответ от банка. Оплата может занять до 10 минут.  Если списание не произошло, попробуйте повторить платеж либо нажмите <a href={paymentUrl} rel="noreferrer" target="_blank">здесь</a></p>
                                                <div className="bb_spinner small"></div>
                                            </div>
                                        ) : purchaseStatus.code === 102 ? (
                                            <div className="successState">
                                                <div className="qrImg">
                                                    <img src={qrPurchase} alt="книги билайн" />
                                                </div>
                                                <p>Отсканируйте QR-код для загрузки приложения</p>
                                                <div className="badgesWrapper">
                                                    <AppBadge market="appStore" />
                                                    <AppBadge market="googlePlay" />
                                                    <AppBadge market="appGallery" />
                                                    <AppBadge market="ruStore" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="bb_spinner medium"></div>
                                        ) }
                                    </div>
                                    { [100, 101].includes(purchaseStatus.code) ? (
                                        <div className="statusWrapperBottom">
                                            Вы будете переправлены на сайт платёжной системы, чтобы совершить платёж.
                                        </div>
                                    ) : null }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <Footer />
        </div>
    )
}

export default Purchase;