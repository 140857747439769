import React, { useEffect, useState } from "react";
import "./CookieNotification.css"
import Button from "../../Button/Button";
import { NavLink } from "react-router-dom";

function CookieNotification(){

    const [cookiesNotifyer, setCookiesNotifyer] = useState(false);

    useEffect(() => {
        if (!localStorage.hasOwnProperty('bbooks_acceptedCookies') || +localStorage.getItem('bbooks_acceptedCookies') === 0){
            localStorage.setItem('bbooks_acceptedCookies', 0);
            setCookiesNotifyer(true);
        }
    }, [])        

    function CloseNotification(){
        localStorage.setItem('bbooks_acceptedCookies', 1);
        setCookiesNotifyer(false);
    }

    if (!cookiesNotifyer){
        return null;
    }

    return(
        <div className="cookieNotificationPanel-wrapper">
            <div className="s-bg">
                <div className="cookieNotificationPanel">
                    <div className="cookieNotificationPanel-text">
                        На сайте используются cookies. Продолжая использовать сервис, вы даёте согласие и принимаете <NavLink to="/policy" target="_blank" rel="norefferer" >пользовательское соглашение</NavLink>
                    </div>
                    <Button onClick={CloseNotification} text="Принять" buttonStyle={"thin"} />
                </div>
            </div>
        </div>
    )
}

export default CookieNotification;