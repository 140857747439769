import React from "react";
import "./AppBonuses.css"
import bonus1 from "../../img/new/bonus1.svg"
import bonus2 from "../../img/new/bonus2.svg"
import bonus3 from "../../img/new/bonus3.svg"
import bonus4 from "../../img/new/bonus4.svg"

function AppBonuses(){
    return (
        <div className="s-bg">
            <div className="appBonuses-wrapper">
                <div className="appBonuses-item">
                    <div className="appBonuses-item-left">
                        <img src={bonus1} alt="приложение книги билайн" />
                    </div>
                    <div className="appBonuses-item-right">
                        <h2>Синхронизация текстовых и аудиокниг</h2>
                        <p>Продолжайте слушать с того же места, на котором остановились читать</p>
                    </div>
                </div>
                <div className="appBonuses-item">
                    <div className="appBonuses-item-left">
                        <img src={bonus3} alt="приложение книги билайн" />
                    </div>
                    <div className="appBonuses-item-right">
                        <h2>Голосовой ввод в поиске</h2>
                        <p>Нажмите на значок микрофона, произнести название – и книга найдется</p>
                    </div>
                </div>
                <div className="appBonuses-item">
                    <div className="appBonuses-item-left">
                        <img src={bonus2} alt="приложение книги билайн" />
                    </div>
                    <div className="appBonuses-item-right">
                        <h2>Удобный ридер с расширенными настройками</h2>
                        <p>Меняйте цвет фона, чтобы не уставали глаза, и ориентируйтесь в книге по цветному прогрессу</p>
                    </div>
                </div>
                <div className="appBonuses-item">
                    <div className="appBonuses-item-left">
                        <img src={bonus4} alt="приложение книги билайн" />
                    </div>
                    <div className="appBonuses-item-right">
                        <h2>Офлайн-доступ</h2>
                        <p>Читайте и слушайте скачанные книги — даже когда нет интернета</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppBonuses;