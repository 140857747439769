import React, { useState } from "react";
import "./FAQitem.css"
import { FaMinus, FaPlus } from "react-icons/fa";

function FAQitem(props){

    const [faqItemShown, setFaqItemShown] = useState(false);

    return (
        <div className="FAQitem">
            <div className="FAQitem-topline" onClick={() => setFaqItemShown(!faqItemShown)}>
                <div className="FAQitem-title">
                    {props?.title}
                </div>
                <div className="FAQitem-toggleButton">
                    {faqItemShown ? <FaMinus /> : <FaPlus />}
                </div>
            </div>
            {faqItemShown && (<div className="FAQitem-text">
                {props.children}
            </div>) }
        </div>
    )
}

export default FAQitem;