import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import SearchService from "../../Services/SearchService";
import { booksCountText, shelfCountText, authorCountText } from "../../Functions/Functions";
import "./Search.css";
import BookContainerHorisontal from "../../Components/BookContainerHorisontal/BookContainerHorisontal";
import { MdArrowForwardIos } from "react-icons/md";
import catSearch from "../../img/cat_search.svg";
import { IoMdClose } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import ShelfSearchResult from "../../Components/ShelfSearchResult/ShelfSearchResult";
import AuthorSearchResult from "../../Components/AuthorSearchResult/AuthorSearchResult";
// import { AppContext } from "../../Hooks/appContext";
// import UrlService from "../../Services/UrlService";
// import axios from "axios";

function Search(){

    const searchService = new SearchService();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const keyword = searchParams.get("s");
    let newKeyword = keyword;

    const inputRef = useRef();

    // const urlService = new UrlService();
    // const { userLoggedInCtx, userTokenCtx, loadedCtx, userSubscriptionsCtx } = useContext(AppContext);

    const [objectResources, setObjectResources] = useState({data: false, loaded: false});
    const [resultBooks, setResultBooks] = useState({data: false, loaded: false});
    const [resultShelves, setResultShelves] = useState({data: false, loaded: false});
    const [resultAuthors, setResultAuthors] = useState({data: false, loaded: false});
    const [resultAudio, setResultAudio] = useState({data: false, loaded: false});
    // const [booksResultPage, setBooksResultPage] = useState(0);
    // const [authorsResultPage, setAuthorsResultPage] = useState(0);
    // const [shelvesResultPage, setShelvesSearchPage] = useState(0);
    // const [audioResultPage, setaudioResultPage] = useState(0);

    // const [userBoughtBooks, setUserBoughtBooks] = useState({data: false, loaded: false});
    // const [userFavBooks, setUserFavBooks] = useState({data: [], loaded: false});
    // const [userSubsInfoLoaded, setUserSubsInfoLoaded] = useState(false);    

    const [displayedType, setDisplayedType] = useState("all");

    const isInitialMount = useRef(true);

    useEffect(() => {
        getSearchResources();
    // eslint-disable-next-line
    }, [])

    // useEffect(() => {
    //     if (loadedCtx){
    //         if (!userLoggedInCtx || !userTokenCtx){
    //             setUserSubsInfoLoaded(true);
    //         } else {
    //             if (userSubscriptionsCtx){
    //                 getUserSubsData();
    //             }
    //         }
    //     }
    // // eslint-disable-next-line
    // }, [userLoggedInCtx, userTokenCtx, loadedCtx, userSubscriptionsCtx])

    // useEffect(() => {
    //     if (userLoggedInCtx && userSubscriptionsCtx
    //         && userBoughtBooks.loaded
    //         && userFavBooks.loaded
    //     ){
    //         setUserSubsInfoLoaded(true);
    //     }
    // }, [userBoughtBooks, userFavBooks, userSubscriptionsCtx, userLoggedInCtx])

    useEffect(() => {
        if (objectResources.loaded && objectResources?.data?.data?.data){
            const resIds = objectResources?.data?.data?.data;
            searchService.getObject(resIds?.books?.resource).then(data => {setResultBooks({data: data?.data?.data, loaded: true})}).catch((e) => {setErrorResult(e)});
            searchService.getObject(resIds?.shelves?.resource).then(data => {setResultShelves({data: data?.data?.data, loaded: true})}).catch((e) => {setErrorResult(e)});
            searchService.getObject(resIds?.abooks?.resource).then(data => {setResultAudio({data: data?.data?.data, loaded: true})}).catch((e) => {setErrorResult(e)});
            searchService.getObject(resIds?.authors?.resource).then(data => {setResultAuthors({data: data?.data?.data, loaded: true})}).catch((e) => {setErrorResult(e)});
            setDisplayedType("all");
        }
    // eslint-disable-next-line        
    }, [objectResources]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [displayedType])

    useLayoutEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            renewSearch();
        }
    // eslint-disable-next-line
    }, [keyword]);    

    function renewSearch(){
        setObjectResources({data: false, loaded: false});
        setResultBooks({data: false, loaded: false});
        setResultShelves({data: false, loaded: false});
        setResultAuthors({data: false, loaded: false});
        setResultAudio({data: false, loaded: false});
        setDisplayedType("all");
        getSearchResources();
    }

    function getSearchResources(){
        searchService.getResources(keyword)
        .then(data => {
            setObjectResources({data:data, loaded: true});
        })
        .catch((e) => {
            // console.log(e);
        })
    }

    function setErrorResult(e){
        console.log(e);
    }

    function onTodoChange(value){
        newKeyword = value;
    } 
    
    function searchInputKeyDown(event){
        if(event.key === 'Enter'){
            searchSubmit()
        }
    }    
    
    function searchSubmit(){
        navigate('/search/?s='+newKeyword);
    }

    function clearSearchInput(){
        inputRef.current.value = '';
    }

    function changeDisplayedType(type){
        if ((type === "all" && sumAllHits() > 0) || (objectResources?.data?.data?.data[type]?.hits > 0) ){
            setDisplayedType(type)
        }
    }

    function checkIfEmpty(type){
        return type === "all" ? (sumAllHits() === 0 ? "empty" : "") : objectResources?.data?.data?.data[type]?.hits === 0 ? "empty" : "";
    }

    function sumAllHits(){
        return objectResources?.data?.data?.data?.books?.hits
            + objectResources?.data?.data?.data?.abooks?.hits
            + objectResources?.data?.data?.data?.authors?.hits
            + objectResources?.data?.data?.data?.shelves?.hits || 0;
    }

    function bookSubsData(){
        return [];
        // const subs = {
        //     userAuthorized: userLoggedInCtx,
        //     userSubscriptions: userSubscriptionsCtx,
        //     userFavs: userFavBooks.data,
        //     bookBooks: userBoughtBooks.data
        // }
        // return subs;
    }

    // function getUserSubsData(){
    //     axios.get(
    //         urlService.getApiURL(), {
    //         params: {
    //             type: "syncState",
    //             uToken: userTokenCtx
    //         }
    //     })
    //     .then((resp) => {
    //         if (!resp.data?.success){
    //             setUserFavBooks({data: [], loaded: true});
    //             return false;
    //         }
    //         const favBooks = resp?.data?.data.filter(book => book.status === "READ").sort(function(a,b){
    //             return b.status_update_date - a.status_update_date;
    //         });
    //         setUserFavBooks({data: favBooks, loaded: true})
    //     });

    //     axios.get(
    //         urlService.getApiURL(), {
    //         params: {
    //             type: "purchased",
    //             uToken: userTokenCtx
    //         }
    //     })
    //     .then((purchasedData) => {
    //         purchasedData.data?.success ? setUserBoughtBooks({data: purchasedData.data?.data, loaded: true}) : setUserBoughtBooks({data: false, loaded: true})
    //     });        


    // }

    if (!keyword){
        navigate('/');
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="page-content">
                <div className="contentWrapper">
                    <div className="s-bg">
                        <div className="searchContent">
                            <div className="searchPanel">
                                <div className="searchInput">
                                    <input ref={inputRef} className="searchValue" defaultValue={keyword} type="text" autoFocus onKeyDown={searchInputKeyDown} onChange={e => onTodoChange(e.target.value)} />
                                    <div className="clearButton" onClick={() => clearSearchInput()}>
                                        <IoMdClose />
                                    </div>
                                    <div className="searchButton" onClick={() => searchSubmit()}>
                                        <FiSearch />
                                    </div>
                                </div>
                                <div className="searchCounts">
                                    <div className={`item ${checkIfEmpty("all")} ${displayedType === "all" ? "active" : ""}`} onClick={() => changeDisplayedType("all")}>все <span>{sumAllHits()}</span></div>
                                    <div className={`item ${checkIfEmpty("books")} ${displayedType === "books" ? "active" : ""}`} onClick={() => changeDisplayedType("books")}>книги <span>{objectResources?.data?.data?.data?.books?.hits}</span></div>
                                    <div className={`item ${checkIfEmpty("abooks")} ${displayedType === "abooks" ? "active" : ""}`} onClick={() => changeDisplayedType("abooks")}>аудиокниги <span>{objectResources?.data?.data?.data?.abooks?.hits}</span></div>
                                    <div className={`item ${checkIfEmpty("shelves")} ${displayedType === "shelves" ? "active" : ""}`} onClick={() => changeDisplayedType("shelves")}>полки <span>{objectResources?.data?.data?.data?.shelves?.hits}</span></div>
                                    <div className={`item ${checkIfEmpty("authors")} ${displayedType === "authors" ? "active" : ""}`} onClick={() => changeDisplayedType("authors")}>авторы <span>{objectResources?.data?.data?.data?.authors?.hits}</span></div>
                                    <div className="searchSmallText">
                                        найдено {sumAllHits()} результатов по запросу <br />"{(keyword.length) < 58 ? keyword : `${keyword.slice(0, 55)}...`}"
                                    </div>
                                </div>
                            </div>
                            <div className="searchResults">
                                { 
                                    !(
                                        objectResources.loaded
                                        && resultAudio.loaded 
                                        && resultAuthors.loaded 
                                        && resultBooks.loaded 
                                        && resultShelves.loaded
                                        // && userSubsInfoLoaded
                                    ) ? (
                                        <div className="bb_spinner medium"></div>
                                    ) : !(
                                        resultAudio?.data?.length
                                        || resultAuthors?.data?.length
                                        || resultBooks?.data?.length
                                        || resultShelves?.data?.length
                                    ) ? (
                                        <div className="emptyResult">
                                        <div className="emptyImg">
                                            <img src={catSearch} alt="ничего не найдено" />
                                        </div>
                                        <div className="emptyText">
                                            Ничего не найдено,<br />проверьте запрос на опечатки
                                        </div>
                                    </div>
                                    ) : 
                                    <>
                                        {displayedType === "all" ? (
                                            <>
                                                {resultBooks?.data?.length ? (
                                                    <div className="searchResult">
                                                        <div className="searchResultTitle">
                                                            <div className="searchType">
                                                                Книги
                                                            </div>
                                                            <div className="searchCount" onClick={() => setDisplayedType("books")}>
                                                                всего {objectResources?.data?.data?.data?.books?.hits} {booksCountText(objectResources?.data?.data?.data?.books?.hits)} <MdArrowForwardIos />
                                                            </div>
                                                        </div>
                                                        <div className="searchResultContent">
                                                            {resultBooks?.data.slice(0,4).map((book, key) => <BookContainerHorisontal key={key} bookData={book} subsData = {bookSubsData(book)} type={book.TYPE} />)}
                                                        </div>
                                                        {
                                                            resultBooks?.data?.length > 4 
                                                            ? <div onClick={() => setDisplayedType("books")} className="searchResultAllButton">показать все книги <MdArrowForwardIos /></div>
                                                            : null
                                                        }
                                                    </div>
                                                ) : null }
                                                {resultAudio?.data?.length ? (
                                                    <div className="searchResult">
                                                        <div className="searchResultTitle">
                                                            <div className="searchType">
                                                                Аудиокниги
                                                            </div>
                                                            <div className="searchCount" onClick={() => setDisplayedType("abooks")}>
                                                                всего {objectResources?.data?.data?.data?.abooks?.hits} {booksCountText(objectResources?.data?.data?.data?.abooks?.hits)} <MdArrowForwardIos />
                                                            </div>
                                                        </div>
                                                        <div className="searchResultContent">
                                                            {resultAudio?.data.slice(0,4).map((book, key) => <BookContainerHorisontal key={key} bookData={book} subsData = {bookSubsData(book)} type={book.TYPE} />)}
                                                        </div>
                                                        {
                                                            resultAudio?.data?.length > 4 
                                                            ? <div onClick={() => setDisplayedType("abooks")} className="searchResultAllButton">показать все аудиокниги <MdArrowForwardIos /></div>
                                                            : null
                                                        }    
                                                    </div>
                                                ) : null }
                                                {resultShelves?.data?.length ? (
                                                    <div className="searchResult">
                                                        <div className="searchResultTitle">
                                                            <div className="searchType">
                                                                Полки
                                                            </div>
                                                            <div className="searchCount" onClick={() => setDisplayedType("shelves")}>
                                                                всего {objectResources?.data?.data?.data?.shelves?.hits} {shelfCountText(objectResources?.data?.data?.data?.shelves?.hits)} <MdArrowForwardIos />
                                                            </div>
                                                        </div>
                                                        <div className="searchResultContent">
                                                            {resultShelves?.data.slice(0,2).map((shelf, key) => <ShelfSearchResult key={key} shelfData={shelf} />)}
                                                        </div>
                                                        {
                                                            resultShelves?.data?.length > 4 
                                                            ? <div onClick={() => setDisplayedType("shelves")} className="searchResultAllButton">показать все полки <MdArrowForwardIos /></div>
                                                            : null
                                                        }    
                                                    </div>
                                                ) : null }
                                                {resultAuthors?.data?.length ? (
                                                    <div className="searchResult">
                                                        <div className="searchResultTitle">
                                                            <div className="searchType">
                                                                Авторы
                                                            </div>
                                                            <div className="searchCount" onClick={() => setDisplayedType("authors")}>
                                                                всего {objectResources?.data?.data?.data?.authors?.hits} {authorCountText(objectResources?.data?.data?.data?.authors?.hits)} <MdArrowForwardIos />
                                                            </div>
                                                        </div>
                                                        <div className="searchResultContent">
                                                            {resultAuthors?.data.slice(0,2).map((author, key) => <AuthorSearchResult key={key} authorData={author} />)}
                                                        </div>
                                                        {
                                                            resultAuthors?.data?.length > 4 
                                                            ? <div onClick={() => setDisplayedType("authors")} className="searchResultAllButton">показать всех авторов <MdArrowForwardIos /></div>
                                                            : null
                                                        }    
                                                    </div>
                                                ) : null }                                             
                                            </>
                                        ) : displayedType === "books" ? (
                                            <div className="searchResult">
                                                <div className="searchResultTitle">
                                                    <div className="searchType">
                                                        Книги
                                                    </div>
                                                    <div className="searchCount">
                                                        всего {objectResources?.data?.data?.data?.books?.hits} {booksCountText(objectResources?.data?.data?.data?.books?.hits)}
                                                    </div>
                                                </div>
                                                <div className="searchResultContent">
                                                    {resultBooks?.data.map((book, key) => <BookContainerHorisontal key={key} bookData={book} subsData = {bookSubsData(book)} type={book.TYPE} />)}
                                                </div>  
                                            </div>                                            
                                        ) : displayedType === "abooks" ? (
                                            <div className="searchResult">
                                                <div className="searchResultTitle">
                                                    <div className="searchType">
                                                        Аудиокниги
                                                    </div>
                                                    <div className="searchCount">
                                                        всего {objectResources?.data?.data?.data?.abooks?.hits} {booksCountText(objectResources?.data?.data?.data?.abooks?.hits)}
                                                    </div>
                                                </div>
                                                <div className="searchResultContent">
                                                    {resultAudio?.data.map((book, key) => <BookContainerHorisontal key={key} bookData={book} subsData = {bookSubsData(book)} type={book.TYPE} />)}
                                                </div>  
                                            </div>
                                        ) : displayedType === "shelves" ? (
                                            <div className="searchResult">
                                                <div className="searchResultTitle">
                                                    <div className="searchType">
                                                        Полки
                                                    </div>
                                                    <div className="searchCount">
                                                        всего {objectResources?.data?.data?.data?.shelves?.hits} {shelfCountText(objectResources?.data?.data?.data?.shelves?.hits)}
                                                    </div>
                                                </div>
                                                <div className="searchResultContent">
                                                    {resultShelves?.data.map((shelf, key) => <ShelfSearchResult key={key} shelfData={shelf} />)}
                                                </div>  
                                            </div>                                            
                                        ) : displayedType === "authors" ? (
                                            <div className="searchResult">
                                                <div className="searchResultTitle">
                                                    <div className="searchType">
                                                        Авторы
                                                    </div>
                                                    <div className="searchCount">
                                                        всего {objectResources?.data?.data?.data?.authors?.hits} {authorCountText(objectResources?.data?.data?.data?.authors?.hits)}
                                                    </div>
                                                </div>
                                                <div className="searchResultContent">
                                                    {resultAuthors?.data.map((author, key) => <AuthorSearchResult key={key} authorData={author} />)}
                                                </div>  
                                            </div>
                                        ) : null }
                                    </>
                                }                          
                            </div>
                            {(
                                resultAudio?.data?.length
                                || resultAuthors?.data?.length
                                || resultBooks?.data?.length
                                || resultShelves?.data?.length
                            ) ? (
                                <div className="searchBottomText">
                                    Мы проверили даже на самых дальних полках, но если здесь нет того, что вы искали, может попробуем изменить запрос?
                                </div> 
                            ) : null }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Search;