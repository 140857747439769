import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Button from "../../Components/Button/Button";
import "./NotFound.css"

function NotFound(){
    return (
        <div className="page-wrapper">
            <Header />
                <div className="page-content">
                    <div className="contentWrapper">
                        <div className="s-bg">
                            <div className="notFound">
                                <div className="square">
                                    <h1>404</h1>
                                </div>
                                <div className="bigText">
                                    <h2>Такой страницы не существует</h2>
                                </div>
                                <Button text="На главную" action="url" href="/" />
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    )
}

export default NotFound;