import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './SwiperMainBanner.css';
import MainBanner from "../../Banners/MainBanner/MainBanner";
import ShowCaseBanner from "../../Showcase/Sections/ShowCaseBanner";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import ReadMeBanner from "../../Banners/ReadmeBanner/ReadmeBanner";
import aiBannerImg from "../../../img/banner_ai.png";


function SwiperMainBanner(props){

    const bannerContent = {
        "style": {
            "webImageUrl": "https://115494b5-5aba-4a42-96f4-e7d46bf93636.selstorage.ru/AI_search/web1.png"
        },
        "button": {
            "url": "https://beelinebooks-ai-search.ru/app",
            "action": "URL",
        }
    };  

    const aiBannerContent = {
        "style": {
            "webImageUrl": aiBannerImg
        },
        "button": {
            "url": "https://bigdata.beeline.ru/birthday?utm_source=hb&utm_medium=promo&utm_campaign=beebooks",
            "action": "URL",
        }
    };      

    return (
        <div className="swiperMainBanner-wrapper">
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                speed={750}
                navigation={{
                    nextEl: '.swiperMainBanner-next',
                    prevEl: '.swiperMainBanner-prev',
                }}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                }}
                loop={true}
                modules={[Pagination, Navigation, Autoplay]}
                className="swiperMainBanner"
            >
                <SwiperSlide >
                    <MainBanner />
                </SwiperSlide>
                <SwiperSlide >
                    <div className="contentWrapper" style={{marginBottom: '54px'}}>
                        <ShowCaseBanner content={bannerContent} />
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <ReadMeBanner />
                </SwiperSlide>
                <SwiperSlide >
                    <div className="contentWrapper" style={{marginBottom: '28px'}}>
                        <ShowCaseBanner content={aiBannerContent} />
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className="swiperMainBanner-prev"><MdArrowBackIos /></div>
            <div className="swiperMainBanner-next"><MdArrowForwardIos /></div>
        </div>
      );    
}

export default SwiperMainBanner;
