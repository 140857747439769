import React from "react";
import { NavLink } from "react-router-dom";
import badgeIos from "../../img/new/appStore.svg"
import badgeAndroid from "../../img/new/googlePlay.svg"
import badgeAppGallery from "../../img/new/appGallery.svg"
import badgeRuStore from "../../img/new/ruStore.svg"
import UrlService from "../../Services/UrlService";

function AppBadge(props){

    const urlService = new UrlService();

    const badgeData = {
        img: null,
        src: "#",
    }

    switch(props.market){
        case "appStore":
            badgeData.img = badgeIos;
            break;
        case "googlePlay":
            badgeData.img = badgeAndroid;
            break;
        case "ruStore":
            badgeData.img = badgeRuStore;
            break;
        case "appGallery":
            badgeData.img = badgeAppGallery;
            break;   
        default:
            break;
    }

    badgeData.src = urlService.getAppLink(props.market);

    return (
        <NavLink target="_blank" to={badgeData.src} >
            <img src={badgeData.img} alt="приложение книги билайн" />
        </NavLink>
    )
}

export default AppBadge