import React from "react";
import "./Preview.css"

class Preview extends React.Component{

    render(){

        let preview = "";

        switch (this.props.type) {
            case 'sR-title':
                preview = <div className="sR-title"></div>;
                break;
            case 'sR-bookTitle':
                preview = <div className="sR-bookTitle"></div>;
                break;
            case 'sR-bookAuthor':
                preview = <div className="sR-bookAuthor"></div>;
                break;
            default:
                preview = <div></div>;
        }

        return <React.Fragment>{preview}</React.Fragment>
    }

}

export default Preview;

