import React, { useState, useEffect } from "react";
import SwiperCentered from "../../Swiper/SwiperCentered/SwiperCentered";
import Preview from "../../Preview/Preview";
import UrlService from "../../../Services/UrlService";
import axios from "axios";
import { NavLink } from "react-router-dom";
// import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

function ShowCaseShelfAnimatedAudio(props){

    const urlService = new UrlService();

    const [shelfData, setShelfData] = useState(null);
    const [apiRequestResult, setApiRequestResult] = useState(false);

    useEffect(() => {
        axios.get(
            urlService.getApiURL(), {
            params: {
                sId: props?.content?.identifiers[0],
                type: "shelf"
            }
        })
        .then((data) => {
            setApiRequestResult(true);
            const responseData = data?.data?.data;
            if (!responseData || !responseData[0]?.BOOKS?.length) return false
            let shelfData = data?.data?.data[0];
            shelfData.BOOKS = shelfData?.BOOKS?.sort((a, b) => 0.5 - Math.random())
            setShelfData(shelfData);
        });
        // eslint-disable-next-line
    }, []);

    if (apiRequestResult && !shelfData?.BOOKS){
        return null;
    }

    return (
            <div className="showcaseRow sR-shelfAnimatedAudio">
                <div className="s-bg">
                    <div className="sR-titleRow">
                        <div className="sR-titleRow-title">
                            {props ? (<h2>{props?.content?.subtitle}</h2>) : <Preview type={"sR-title"} />}
                        </div>
                        <div className="sR-titleRow-href">
                            {/* <div className="SwiperCentered-prev"><MdArrowBackIos /></div> */}
                            {/* <div className="SwiperCentered-next"><MdArrowForwardIos /></div> */}
                            {shelfData ? <NavLink to={"/shelf/"+props?.content?.identifiers[0]} className="animate__animated animate__fadeIn">все</NavLink> : "" }
                        </div>
                    </div>
                </div>        
                <div className="sR-contentRow">
                    {shelfData ? <SwiperCentered shelfBooks={shelfData} /> : null}
                </div>
            </div>
    )
    
}

export default ShowCaseShelfAnimatedAudio;