import React from "react";
import "./MainBanner.css"
import cover1 from "../../../img/spring-banner-1.jpg"
import cover2 from "../../../img/spring-banner-2.jpg";
import cover3 from "../../../img/spring-banner-3.jpg";
import Button from "../../Button/Button";

function MainBanner(){
    return (
        <div className="mainBanner-backer">
            <div className="s-bg">
                <div className="mainBanner-wrapper">
                    <div className="mainBanner-content-left">
                        <div className="mainBanner-content-title">
                            начните<br />с новой строки
                        </div>
                        <div className="mainBanner-content-secondaryText">
                            читайте лучшие книги ведущих издательств
                        </div>
                        <div className="mainBanner-content-greyBg">
                            подбор книг искусственным интеллектом
                        </div>
                        <div className="mainBanner-content-button">
                            <Button text="Хочу" action={"modal"} modalSrc={"subscriptionPanel"} buttonStyle={"w-144"} additData={{type:"promo"}} customGoal={"try_button_promo_october"} />
                        </div>
                        <div className="mainBanner-content-smallText">
                            45 дней бесплатно, далее 299 руб. в месяц.<br />
                            Для абонентов всех операторов
                        </div>
                    </div>
                    <div className="mainBanner-content-right">
                        <div className="mainBanner-content-covers">
                            <div className="mainBanner-content-cover"><img alt="книги билайн" src={cover1}/></div>
                            <div className="mainBanner-content-cover"><img alt="книги билайн" src={cover2}/></div>
                            <div className="mainBanner-content-cover"><img alt="книги билайн" src={cover3}/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBanner;