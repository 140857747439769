class UrlService{

    prodUrl = "https://books.beeline.ru";
    // prodUrl = "https://books.beebooks.ru";

    serviceUrl = "https://books.beebooks.ru";

    apiUrl = `${this.serviceUrl}/api/index.php`;

    midUrl = `${this.serviceUrl}/mid/auth/authorize/index.php`;

    appMetricaUrl = `https://3463177.redirect.appmetrica.yandex.com`;
    
    userSubsUrl = `${this.serviceUrl}/userSubs.php`;

    subscriptionBaseUrl = `${this.serviceUrl}/subscription.php`;
    subscriptionPromoUrl = `${this.serviceUrl}/subscriptionPromoNew-45-299.php`;
    checkingStatusUrl = `${this.serviceUrl}/checkStatusSBP.php`;

    storesUrls = {
        appStore: "https://itunes.apple.com/ru/app/%D1%87%D0%B8%D1%82%D0%B0%D0%BB%D0%BA%D0%B0-fb2-%D0%BE%D1%82-%D0%B1%D0%B8%D0%BB%D0%B0%D0%B9%D0%BD-%D0%BA%D0%BD%D0%B8%D0%B3/id1132022519?mt=8",
        googlePlay: "https://play.google.com/store/apps/details?id=ru.beeline.beebookreader",
        ruStore: "https://apps.rustore.ru/app/ru.beeline.beebookreader",
        appGallery: "https://appgallery.huawei.com/#/app/C101184851"
    }

    getProdUrl(){
        return this.prodUrl;
    }

    getServiceUrl(){
        return this.serviceUrl;
    }

    getApiURL(){
        return this.apiUrl;
    }

    getMidUrl(){
        return this.midUrl;
    }

    getUserSubs(){
        return this.userSubsUrl;
    }

    getSubscriptionUrl(){
        return {
            base: this.subscriptionBaseUrl,
            promo: this.subscriptionPromoUrl
        }
    }

    getCheckingUrl(){
        return this.checkingStatusUrl;
    }

    getAppLink(market){
        return this.storesUrls[market];
    }

    getQrCodeLink(adress){
        return `${this.appMetricaUrl}/${this.baseUrl}/${adress}/?appmetrica_tracking_id=316703006169072938&referrer=reattribution%3D1`;
    }

    getReaderUrl(bookId){
        return `${this.prodUrl}/reader/?id=${bookId}`;
    }
}

export default UrlService;