import React from "react";
import { NavLink } from "react-router-dom";

function ShowCaseBanner(props){

    if (!props.content?.style?.webImageUrl){
        return null;
    }

    if (props?.content?.button?.action === "URL"){
        return (
            <NavLink target="_blank" to={props.content.button.url} className="s-bg">
                <div className="showcaseRow sR-banner">
                    <img src={props.content?.style?.webImageUrl} alt={"книги билайн"}/>
                </div>
            </NavLink>
        )
    }


    return (
        <div className="s-bg">
            <div className="showcaseRow sR-banner">
                <img src={props.content?.style?.webImageUrl} alt={"книги билайн"}/>
            </div>
        </div>
    )
}

export default ShowCaseBanner;