import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import "./ModalSubscribe.css"
import UrlService from "../../../../Services/UrlService";
import axios from "axios";
import Button from "../../../Button/Button";
import { NavLink } from "react-router-dom";
import { PiPencilSimpleLight } from "react-icons/pi";
import { AppContext } from "../../../../Hooks/appContext";
import SubscriptionService from "../../../../Services/SubscriptionService";
import paymentCat from "../../../../img/catSelectType.svg"
import waitingCat from "../../../../img/cat-13.svg"
import qrCode from "../../../../img/new/qrApp.svg"
import qrPromo from "../../../../img/new/rq-45-299.png"
import downloadText from "../../../../img/new/qrDecs.svg"
import AppBadge from "../../../AppBadge/AppBadge";
import { ymReachGoal } from "../../../../Functions/Functions";

function ModalSubscribe(props){

    const { userLoggedInCtx, userIsBeeCtx, userTokenCtx, userSubscriptionsCtx } = useContext(AppContext);

    const urlService = new UrlService();
    const subscriptionService = new SubscriptionService();

    const [authorizationStep, setAuthorizationStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [authReqId, setAuthReqId] = useState(null);
    const [userToken, setUserToken] = useState(null);
    const [userAuthorized, setUserAuthorized] = useState(false);
    const [userIsBee, setUserIsBee] = useState(null);
    const [subsType, setSubsType] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [errorData, setErrorData] = useState(false);
    const [tarifUp, setTarifUp] = useState(false);
    const [paymentType, setPaymentType] = useState(0);
    const [paymentUri, setPaymentUri] = useState(null);

    const [selectedPeriod, setSelectedPeriod] = useState("m");

    const phoneInput = React.createRef();
    const regularText= React.createRef();

    const subsChannel = 'AppWeb';
    let checkSubsCount = 0;

    useEffect(() => {
        const subsType = props?.context?.type;
        setSubsType(subsType);
        setSubscriptionData(subscriptionService.getSubscriptionInfo(subsType));
        // eslint-disable-next-line
    }, [subsType]);

    useEffect(() => {
        if (props?.context?.dailyPlan){
            setSelectedPeriod("d");
        }
    // eslint-disable-next-line
    }, [selectedPeriod])


    useEffect(() => {
        if (authReqId){
            setTimeout(function(){
                checkMidProcessing();
            }, 3000);
        }
    // eslint-disable-next-line
    }, [authReqId])

    useEffect(() => {
        if (!userLoggedInCtx && userToken){
            checkIfUserIsBee();
        }
    // eslint-disable-next-line
    }, [userToken])

    useEffect(() => {
        if (userIsBee !== null){
            if (!userLoggedInCtx){
                setUserAuthorized(true);
            }
        }
    // eslint-disable-next-line
    }, [userIsBee])    

    useEffect(() => {
        if (userAuthorized){
            startSubscriptionProcess();
        }
        setTarifUp(!!userSubscriptionsCtx?.extra?.tarifUp);

        return () => { 
            if(userAuthorized && !userLoggedInCtx){
                reloadAndLogin();
            }
        }        
    // eslint-disable-next-line
    }, [userAuthorized])

    useEffect(() => {
        if (phoneNumber){
            setAuthorizationStep(2);
            startAuthorizeWithMID();
        }
    // eslint-disable-next-line
    }, [phoneNumber]);

    useLayoutEffect(() => {
        if (!errorData){
            if (authorizationStep === 5){
                subsType === "promo" ? ymReachGoal('successful_subscribe_promo399') : ymReachGoal('successful_subscribe');
            } else if (authorizationStep === 2){
                subsType === "promo" ? ymReachGoal("activation_button_promo399") : ymReachGoal(`${(subsType === "all") ? "all" : (subsType === "audio") ? "audiobook" : "book"}_${(selectedPeriod === "d") ? "daily" : "monthly"}_choosetap`);
            }
        } else {
            subsType === "promo" ? ymReachGoal('error_subscribe_promo399') : ymReachGoal('error_subscribe');
        }
    // eslint-disable-next-line
    }, [authorizationStep, errorData])

    const checkInputKeyPressed = (e) => {
        if (e.code === 'Enter'){
            processMobilePhone();
        }
    }

    function startAuthorizationProcess(){
        if (userLoggedInCtx){
            setUserIsBee(userIsBeeCtx);
            setUserToken(userTokenCtx);
            setUserAuthorized(true);
            // setUserToken(userTokenCtx);
            // startSubscriptionProcess();
        } else {
            processMobilePhone();
        }
    }

    function startSubscriptionProcess(){
        if (!userIsBee){
            setAuthorizationStep(4); // payment type selection window
            return;
        } else {
            processBeelinePayment()
        }
    }

    function processBeelinePayment(){

        const subscriptionFields = {
            paymentType: paymentType,
			userToken: userToken,
			channel: subsChannel,
			selectedPeriod: selectedPeriod,
			subsType: subsType,
			userIsBee: userIsBee,
            tarifUp: tarifUp,
        }

        subscriptionService.createSubscription(subscriptionFields).then(result => {
            processSubscriptionResult(result)
        })
        .catch(error => {
            getErrorData(9900);
        })
    }

    function processSubscriptionResult(result){
        // if (result?.status === "success" && result?.data?.status_text === "OK"){
        if ([result?.status, result?.data?.status].includes("success") && [result?.data?.status_text, result?.data?.data?.status_text].includes("OK")){
            if (result?.data?.payment_uri || result?.data?.data?.payment_uri){
                const paymentUri = result?.data?.payment_uri || result?.data?.data?.payment_uri;
                setPaymentUri(paymentUri);
                setAuthorizationStep(6);
                window.open(paymentUri, '_blank').focus();
                setTimeout(function(){
                    paymentProcessing();
                },3000)	                
            } else {
                setAuthorizationStep(5);
            }
        } else {
            let errorCode = result?.data?.status_code;
            (subsType === "promo") && (errorCode = errorCode+"001");
            getErrorData(errorCode)
        }
    }

    function paymentProcessing(){
        checkSubsCount++;
        if (checkSubsCount >= 100) {
            getErrorData(9900);
            return false;
        }
        const requiredPaymentInfo = {
            paymentType: paymentType,
			userToken: userToken,
			selectedPeriod: selectedPeriod,
			subsType: subsType,
        }
        subscriptionService.checkCardPayment(requiredPaymentInfo).then(result => {
            console.log(result);
            (result?.data?.status === "processing") && setTimeout(() => paymentProcessing(), 5000);                
            (result?.data?.status === "success") && setAuthorizationStep(5);
            (result?.data?.status === "error") && getErrorData(9900);
        })
        .catch(error => {
            paymentProcessing();
        })
    }

    function processNonBeelinePayment(){
        const paymentType = 1;
        setPaymentType(paymentType);

        const subscriptionFields = {
            paymentType: paymentType,
			userToken: userToken,
			channel: subsChannel,
			selectedPeriod: selectedPeriod,
			subsType: subsType,
			userIsBee: userIsBee,
            tarifUp: tarifUp,
        }

        subscriptionService.createSubscription(subscriptionFields).then(result => {
            processSubscriptionResult(result)
        })
        .catch(error => {
            getErrorData(9900);
        })        
    }

    function processMobilePhone(){
		let phoneNumber = phoneInput.current.value;
		(phoneNumber[0] === "9" && phoneNumber.length === 10) && (phoneNumber = "7" + phoneNumber); 
		let expectedLength = phoneNumber[0] === "+" ? 12 : 11;
		if (phoneNumber.length !== expectedLength){
			mobilePhone_showError("Необходимо ввести корректный номер телефона")
			return false;
		}
		if (phoneNumber[0] === "+"){ phoneNumber = phoneNumber.substring(1, phoneNumber.length); }
		else if (phoneNumber[0] === "8"){ phoneNumber = "7"+phoneNumber.substring(1, phoneNumber.length); }
        setPhoneNumber(phoneNumber);
        // setAuthorizationStep(2);
        // startAuthorizeWithMID();
    }

	function mobilePhone_showError(text){
		let prevText = regularText.current.innerHTML;
		regularText.current.innerHTML = text;
		regularText.current.classList.add("errorText");
		phoneInput.current.classList.add("errorBorder");
		setTimeout(() =>{
                regularText.current.innerHTML = prevText;
				regularText.current.classList.remove("errorText");
				phoneInput.current.classList.remove("errorBorder");
        }, 3000);
	}

    function startAuthorizeWithMID(){
        ymReachGoal("request_mobileID");

        const body = {
            method: "authorize", 
            msisdn : phoneNumber
        }
        const form = new FormData()
        Object.entries(body).forEach(([key, value]) => form.append(key, value))   

        axios.post(urlService.getMidUrl(), form)
        .then((data) => {
            let obj = data.data;
            if (obj.status === "success" && obj.auth_req_id){
                setAuthReqId(obj.auth_req_id);
            } else {
                getErrorData(9900);
            }            
        })
        .catch((e) => {
            getErrorData(9900);
        }
        );
	}

    function checkMidProcessing(){
        const body = { 
            method: "check", 
            auth_req_id : authReqId
        }
        const form = new FormData()
        Object.entries(body).forEach(([key, value]) => form.append(key, value))    
            
        axios.post(urlService.getMidUrl(), form)
        .then((data) => {
            let obj = data.data;
            if (obj.status === "processing"){
                setTimeout(function(){
                    checkMidProcessing();
                }, 3000)
            } else if (obj.status === "success"){
                ymReachGoal('confirm_mobileID');
                const userData={
                    "token" : obj.msisdnToken,
                    "msisdn" : phoneNumber,
                }
                localStorage.setItem('user', JSON.stringify(userData));                
                setUserToken(obj.msisdnToken);
            }  else {
                getErrorData(9900);
            }          
        })
        .catch((e) => {
            getErrorData(9900);
        })
	}

    function checkIfUserIsBee(){
        // setUserAuthorized(true);
        axios.get(
            urlService.getApiURL(), {
            params: {
              uToken: userToken,
              type: "checkUser"
            }
          })
          .then((data) => {
              if (data?.data?.success && !data?.data?.data){
                  setUserIsBee(false);
              } else {
                setUserIsBee(true);
              }
          })
          .catch((e) => {
            setUserIsBee(true);
          })        
    }

	function reloadAndLogin(){
        if (!userLoggedInCtx){
            const userData={
                "token" : userToken,
                "msisdn" : phoneNumber,
            }
            localStorage.setItem('user', JSON.stringify(userData));
        }
        window.location.reload();		
	}

    function changeSubscriptionPeriod(){
        setAuthorizationStep(3);
    }

    function getErrorData(errorCode){
        const errorData = subscriptionService.getErrorInfo(errorCode);
        setErrorData(errorData);
    }

    if (!subscriptionData){
        return null;
    }

    return (
        <div className="modalSubscribe">
            { ( !errorData ) ? (
                (authorizationStep === 1) ? (
                    <>
                        <h2>Подписка {subscriptionData.title}</h2>
                        { subsType !== "promo" ? (
                            <div className="subscribePreInfo-wrapper">
                                <span>Выбран тариф</span>
                                <div className="subscribePreInfo">
                                    <div className="subscribePreInfo-title">{ subscriptionData.title }</div>
                                    <div className="subscribePreInfo-price">{ (selectedPeriod === "m" && subscriptionData.name === "all" && tarifUp) ? subscriptionData?.prices["up"] : subscriptionData?.prices[selectedPeriod] } ₽ / {selectedPeriod === "d" ? "день" : "месяц" }</div>
                                    {/* {((userLoggedInCtx && userIsBee) || !userLoggedInCtx) ? <div className="subscribePreInfo-edit" onClick={changeSubscriptionPeriod}><PiPencilSimpleLight /></div> : null} */}
                                    {((userLoggedInCtx && userIsBee) || !userLoggedInCtx) ? <div className="subscribePreInfo-edit" onClick={changeSubscriptionPeriod}><PiPencilSimpleLight /></div> : null}
                                </div>
                            </div> 
                        ) : null }
                        {userLoggedInCtx ? null : (
                            <>
                                <p ref={regularText} className="regularText">Номер телефона</p>
                                <input ref={phoneInput} className="phoneNumber" type="text" placeholder="+7 000 000-00-00" autoComplete="off" maxLength="12" onKeyDown={checkInputKeyPressed} />
                            </>
                        )}
                        <Button onClick={startAuthorizationProcess} buttonStyle={"w-fw"} text="Подключить" customGoal={"request_mobileID"} />
                        {subsType === "promo" ? (
                            <p className="smallText">{subscriptionData.lawText}<br />Подключая услугу, вы принимаете <NavLink to={subscriptionData.rulesSrc} target="_blank" > правила акции </NavLink> и <NavLink to="/policy" target="_blank" > пользовательское соглашение </NavLink></p>
                        ) : (
                            <p className="smallText">При входе вы автоматически принимаете <NavLink to="/policy" target="_blank" >условия использования</NavLink> сервиса</p>
                        ) }
                    </> 
                ) 
                : (authorizationStep === 2) ? (
                    <>
                        <h2>Подтвердите вход</h2>
                        <p className="waitText">Сейчас на экране вашего смартфона отобразится запрос на подтверждение входа</p>
                        <div className="bb_spinner"></div>
                    </>
                ) : (authorizationStep === 3) ? (
                    <>
                        <h2>Подписка {subscriptionData.title}</h2>
                        <p className="greyText">{subscriptionData.subTitle}</p>
                        <div className="periodSelection-wrapper">
                            <div className={ (selectedPeriod === "d" ? "selected " : "") + "periodSelection-item" } onClick={() => setSelectedPeriod("d")}>
                                <div className="periodSelection-item-badge">Только для абонентов билайн</div>
                                <div className="periodSelection-item-title">Ежедневная</div>
                                <div className="periodSelection-item-monthPrice">{ subscriptionData.prices.d * 30 } ₽/мес</div>
                                <div className="periodSelection-item-price-wrapper">
                                    <div className="periodSelection-item-price-total">{ subscriptionData.prices.d } ₽</div>
                                </div>
                            </div>
                            <div className={ (selectedPeriod === "m" ? "selected " : "") + "periodSelection-item" } onClick={() => setSelectedPeriod("m")}>
                                <div className="periodSelection-item-badge">Для всех -17%</div>
                                <div className="periodSelection-item-title">Ежемесячная</div>
                                <div className="periodSelection-item-monthPrice">{ subscriptionData.prices.m } ₽/мес</div>
                                <div className="periodSelection-item-price-wrapper">
                                    <div className="periodSelection-item-price-total">{ subscriptionData.prices.m } ₽</div>
                                    <div className="periodSelection-item-price-full">{ subscriptionData.prices.d * 30 } ₽</div>
                                </div>
                            </div>                                    
                        </div>
                        <Button onClick={() => setAuthorizationStep(1)} buttonStyle={"w-fw"} text="Выбрать" />
                        <p className="smallText">При входе вы автоматически принимаете <NavLink to="/policy" target="_blank" >условия использования</NavLink> сервиса</p>
                    </>
                ) : (authorizationStep === 4) ? (
                    <>
                        <img alt="книги билайн" src={paymentCat} />
                        <h2>Привяжите карту</h2>
                        <Button onClick={processNonBeelinePayment} buttonStyle={"w-fw mb-20"} text="Привязать" />
                        <p className="waitText">Для активации промо-доступа необходимо ввести данные банковской карты, подписка будет продлена автоматически</p>
                    </>
                ) : (authorizationStep === 5) ? (
                    <>
                        <h2>Подписка оформлена</h2>
                        <p className="waitText">Проверьте статус активации в приложении в разделе "Профиль"</p>
                        <div className="downloadPanel-badges">
                            <div className="downloadPanel-badges-column">
                                <AppBadge market="appStore" />
                                <AppBadge market="googlePlay" />
                                <AppBadge market="appGallery" />
                                <AppBadge market="ruStore" />
                            </div>
                            <div className="downloadPanel-badges-column">
                                {subsType === "promo" ? (
                                    <img src={qrPromo} className="qrCode" alt="приложение книги билайн" />
                                ) : (
                                    <img src={qrCode} className="qrCode" alt="приложение книги билайн" />
                                )}
                            </div>
                            <div className="downloadPanel-badges-column">
                                <img src={downloadText} alt="приложение книги билайн" />
                            </div>
                        </div>
                    </>
                ) : (authorizationStep === 6) ? (
                    <>
                        <img alt="книги билайн" src={waitingCat} />
                        <h2>Почти у цели, переводим в банк</h2>
                        {subsType === "promo" ? (
                            <p className="waitText">Привяжите банковскую карту, чтобы получить промо-доступ. Вы подключите подписку Книги на 14 дней бесплатно, далее 299 рублей в месяц. Для проверки карты мы спишем и вернем 10 рублей.<br />Если страница не открылась, нажмите <NavLink to={paymentUri} target="_blank" >здесь</NavLink><br /><br />Активация промо-подписки может занять до 10 минут</p>
                        ) : (
                            <p className="waitText">Привяжите банковскую карту, чтобы получить промо-доступ. Так по окончании бесплатного периода вы сможете продолжить пользоваться подпиской и сохранить доступ к тысячам книг. Стоимость после окончания бесплатного промо-периода: { subscriptionData.prices.m } р/мес.</p>
                        )}
                        <div className="bb_spinner small"></div>
                    </>
                ) : null
            ) : (
                <>
                    <img className="errorImage" alt="книги билайн" src={errorData.image} />
                    <h2>{errorData.title}</h2>
                    <p className="error_Description">{errorData.subtitle}</p>
                </>
            ) }
        </div>
    )
}

export default ModalSubscribe;