import React from "react";
import { NavLink } from "react-router-dom";
import "./BookContainerHorisontal.css"
import bookPreloader from "../../img/bookPreloader.svg"
import { authorName } from "../../Functions/Functions";
import Badges from "../Badges/Badges";
import vinil from "../../img/vinil.png"
import AdultMask from "../AdultMask/AdultMask";
import { RiStarSFill } from "react-icons/ri";
import stripHtml from "../../Functions/Functions";
import Button from "../Button/Button";
import UrlService from "../../Services/UrlService";

function BookContainerHorisontal(props){

    let book = props?.bookData;
    // let subs = props?.subsData;
    let isAudio = book.TYPE === "audio";

    const urlService = new UrlService();

    const showButtons = false;

    const coverPreview = bookPreloader;

    if (!book){
        return null;
    }    

    return (
        <div className="bookContainerHorisontalWrapper">
            {book ? (
                <NavLink to={"/book/"+book?.ID}>
                    <div className={ (isAudio ? "audioBook" : "") + " bookContainerHorisontal" }>
                        <div className="bookContainerHorisontal-cover">
                            {book?.COVER ? <img src={book?.COVER} alt={book?.TITLE}/> : <img className="coverPreview" src={coverPreview} alt=""/>}
                            <Badges bookData={book} />
                            {isAudio ? (
                                <div className="bookContainerHorisontal-cover-vinil">
                                    <img alt={book?.TITLE} src={vinil} /> 
                                </div>
                            ) : null}
                            <AdultMask adultRating={book?.ADULT} />
                        </div>
                        <div className="bookContainerHorisontal-content">
                            {book?.USER_RATING !== 0 && (
                                <div className="bookContainerHorisontal-rating">
                                    <RiStarSFill /> {book?.USER_RATING }
                                </div>
                            )}
                            <div className="bookContainerHorisontal-title">
                                { book?.TITLE }
                            </div>
                            <div className="bookContainerHorisontal-author">
                                { authorName(book?.AUTHORS) }
                            </div>
                            <div className="bookContainerHorisontal-description">
                                { stripHtml(book?.ANNOTATION) }
                            </div>
                        </div>
                        {showButtons ? (
                            <div className="bookContainerHorisontal-buttons">
                                <Button 
                                    text={"Подробнее"}
                                    action={"modal"} 
                                    modalSrc={"downloadApp"}
                                    buttonStyle="thin w-fw ptb-15"
                                    customQr={urlService.getQrCodeLink(`book/${book?.ID}`)}
                                />
                                <Button 
                                    text={"Читать"}
                                    action={"modal"} 
                                    modalSrc={"downloadApp"}
                                    buttonStyle="thin w-fw ptb-15"
                                    customQr={urlService.getQrCodeLink(`book/${book?.ID}`)}
                                />
                                {/* { bookCanSubscribe.data ? (
                                    userLoggedInCtx ? (
                                        bookData?.IS_EXCLUSIVE ? (
                                            <Button action="modal" modalSrc="subscriptionPanel" text="Подписаться<br/>30 дней бесплатно" buttonStyle="ptb-15 w-fw bp-nb" additData={{type:subscriptionPrice.name}} customGoal={"subscribe_all"} />
                                        ) : <Button action="modal" modalSrc="subscriptionPanel" text="Подписаться" buttonStyle={`withPrice hoverYellow ptb-10 w-fw ${userSubscriptionsCtx?.extra?.tarifUp ? "upColor" : ""}`} additData={{price: userSubscriptionsCtx?.extra?.tarifUp ? subscriptionPrice.prices.up : subscriptionPrice.prices.m, type:subscriptionPrice.name}} />
                                    ) : (
                                        bookData?.IS_EXCLUSIVE ? (
                                            <Button action="modal" modalSrc="authorizationPanel" text="Подписаться<br/>30 дней бесплатно" buttonStyle="withPrice hoverYellow ptb-10 w-fw" additData={{price:subscriptionPrice.prices.m}} />
                                        ) : <Button action="modal" modalSrc="authorizationPanel" text="Подписаться" buttonStyle="withPrice hoverYellow ptb-10 w-fw" additData={{price:subscriptionPrice.prices.m}} />
                                    )
                                ) : null }
                                { (bookData.PRICE > 0 && bookCanByu.data) ? (
                                    userLoggedInCtx ? (
                                        <Button action="url" href={`/purchase/${bookData.ID}`} text="Купить книгу" buttonStyle={`withPrice grey hoverGrey ptb-10 w-fw ${bookSale.data ? "saleButton" : ""}`} additData={{price:bookPrice.data}} />
                                    ) : (
                                        <Button action="modal" modalSrc="authorizationPanel" text="Купить книгу" buttonStyle={`withPrice grey hoverGrey ptb-10 w-fw ${bookSale.data ? "saleButton" : ""}`} additData={{price:bookPrice.data}} />
                                    )
                                ) : null }                             */}
                            </div>
                        ) : null }
                    </div>
                </NavLink>
            ) : null}
        </div>
    )
}

export default BookContainerHorisontal;