import React from "react";
import "./Button.css";
import useModalPanel from "../../Hooks/useModalPanel";
import ModalPanel from "../ModalPanel/ModalPanel";
import { NavLink } from "react-router-dom";
import ModalDownloadApp from "../ModalPanel/ModalContent/ModalDownloadApp/ModalDownloadApp";
import ModalAuthorize from "../ModalPanel/ModalContent/ModalAuthorize/ModalAuthorize";
import ModalSubscribe from "../ModalPanel/ModalContent/ModalSubscribe/ModalSubscribe";
import { ymReachGoal } from "../../Functions/Functions";
import medishares from "../../img/medishares.svg"
import giftIcon from "../../img/gift_light.svg"
import qrIcon from "../../img/qr-code-scan-svgrepo.svg"

function Button(props){ 

    const [isShowingModal, toggleModal] = useModalPanel();

    function bindOnClick(fun = true){
        props?.customGoal && ymReachGoal(props?.customGoal)
        if (typeof fun === "function") {
            fun();
        };
    }

    if (!props?.text && !props?.noButton){
        return null;
    }

    let customIcon;

    switch(props?.customIcon){
        case "giftIcon":
            customIcon = giftIcon;
            break;
        case "medishares":
            customIcon = medishares;
            break;
        case "qrIcon":
            customIcon = qrIcon;
            break;
        default:
            break;
    }    

    if (props?.action === "url"){
        return (
            <React.Fragment>
                {props?.blank ? (
                    <a href={props?.href} target="_blank" rel="noreferrer">
                        <div className={(props?.buttonStyle ? props.buttonStyle : "") + " button"} onClick={()=>bindOnClick()}>
                            {props.customIcon && <img src={customIcon} alt={props?.text} />}<div className="buttonText">{props?.text}</div>
                            {props?.additData?.price ? (
                                <div className="buttonPrice">{props?.additData?.price} ₽</div>
                            ) : null }
                        </div>
                    </a>
                ) : (
                    <NavLink to={props?.href}>
                    <div className={(props?.buttonStyle ? props.buttonStyle : "") + " button"} onClick={()=>bindOnClick()}>
                        <div className="buttonText">{props?.text}</div>
                        {props?.additData?.price ? (
                            <div className="buttonPrice">{props?.additData?.price} ₽</div>
                        ) : null }
                    </div>
                    </NavLink>
                )}

            </React.Fragment>
        )        
    }

    if (props?.action === "modal"){

        if (!props?.modalSrc){
            return null;
        }
        
        let modalContent;

        switch(props.modalSrc){
            case "downloadApp":
                modalContent = <ModalDownloadApp 
                                    customQr={props?.customQr ? props?.customQr : null} 
                                    customTitle={props?.customTitle ? props?.customTitle : null}
                                />
                break;
            case "authorizationPanel":
                modalContent = <ModalAuthorize />
                break;
            case "subscriptionPanel":
                modalContent = <ModalSubscribe context={props.additData} />
                break;                
            default:
                modalContent = <ModalDownloadApp />
                break;
        }

        if (props?.noButton){
            return (
                <React.Fragment>
                    <ModalPanel show={isShowingModal} onCloseButtonClick={toggleModal} content={modalContent} />
                    <div className={"button noButton"} onClick={() => bindOnClick(toggleModal)}>
                        <img src={customIcon} alt="книги билайн" />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <ModalPanel show={isShowingModal} onCloseButtonClick={toggleModal} content={modalContent} />
                    <div className={(props?.buttonStyle ? props.buttonStyle : "") + " button"} onClick={() => bindOnClick(toggleModal)}>
                        {props.customIcon && <img src={customIcon} alt={props?.text} />}
                        <div className="buttonText">{props?.text.replace('<br/>', '\n')}</div>
                        {props?.additData?.price ? (
                            <div className="buttonPrice">{props?.additData?.price} ₽</div>
                        ) : null }
                    </div>
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment>
            <div onClick={() => bindOnClick(props.onClick)} className={(props?.buttonStyle ? props.buttonStyle : "") + " button"} >
                {props?.text}
            </div>
        </React.Fragment>
    )    
    
}

export default Button;