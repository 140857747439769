import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import UrlService from "../../Services/UrlService";


function Offer(){

    const urlService = new UrlService();
    const {id} = useParams();
    const offerId = id;
    let getParams = window.location.search;

    useEffect(() => {
        window.location.href = `${urlService.getServiceUrl()}/offer/${offerId}${getParams}`;
    // eslint-disable-next-line
    }, [])

    

    return (
        <></>
    )
}

export default Offer;