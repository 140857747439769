import React from "react";
import "./DownloadPanelBig.css";
import appScreen from "../../img/new/appScreen.png"
import appLogo from "../../img/new/logo.svg"
import qrCode from "../../img/new/qrApp.svg"
import downloadText from "../../img/new/qrDecs.svg"
import AppBadge from "../AppBadge/AppBadge";

function DownloadPanelBig(){
    return(
        <div className="s-bg">
            <div className="downloadPanel-wrapper">
                <div className="downloadPanel-left">
                    <div className="downloadPanel-logo">
                        <img src={appLogo} alt="книги билайн"/>
                    </div>
                    <div className="downloadPanel-text">
                        <h2>Приложение «билайн книги и аудиокниги»</h2>
                        <p>Личная библиотека в смартфоне или на планшете</p>
                    </div>
                    <div className="downloadPanel-badges">
                        <div className="downloadPanel-badges-column">
                            <AppBadge market="appStore" />
                            <AppBadge market="googlePlay" />
                            <AppBadge market="appGallery" />
                            <AppBadge market="ruStore" />
                        </div>
                        <div className="downloadPanel-badges-column">
                            <img src={qrCode} alt="приложение книги билайн" />
                        </div>
                        <div className="downloadPanel-badges-column">
                            <img src={downloadText} alt="приложение книги билайн" />
                        </div>
                    </div>
                </div>
                <div className="downloadPanel-right">
                    <img src={appScreen} alt="Приложение книги билайн" />
                </div>
            </div>
        </div>
    )
}

export default DownloadPanelBig;