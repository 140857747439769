import React from "react";
import { NavLink } from "react-router-dom";
import "./AuthorSearchResult.css"
import { authorName } from "../../Functions/Functions";
import Badges from "../Badges/Badges";
import vinil from "../../img/vinil.png"
import AdultMask from "../AdultMask/AdultMask";
import { booksCountText } from "../../Functions/Functions";
import { MdArrowForwardIos } from "react-icons/md";

function AuthorSearchResult(authorData){

    const data = authorData.authorData;

    const authorsIterator = Array(4).fill(null);

    if (data?.POPULAR_BOOKS?.length){
        const authorBooks = data?.POPULAR_BOOKS?.slice(0,4);
        authorsIterator.splice(0, authorBooks.length, ...authorBooks)
    }


    if (!data){
        return null;
    }    

    return (
        <div className="authorSearchResultWrapper">
            <NavLink to={"/author/"+data.ID}>
                <div className="authorTitle">{authorName([data])} / {data.BOOKS_COUNT} {booksCountText(data.BOOKS_COUNT)}</div>
                <div className="authorBooks">
                    {
                        authorsIterator.map((book, key) => (
                            <div key={key} className={`bookCover ${book?.TYPE === "audio" ? "audio" : ""}`}>
                                {book?.ID ? (
                                    <>
                                        <img src={book?.COVER200} alt={book?.TITLE} />
                                        <Badges bookData={book} />
                                        {book?.TYPE === "audio" ? (
                                            <div className="bookContainer-cover-vinil">
                                                <img alt={book?.TITLE} src={vinil} /> 
                                            </div>
                                        ) : null}                                        
                                        <AdultMask adultRating={book?.ADULT} />
                                    </>
                                ) : null}
                            </div>
                        ))
                    }
                    <div className="authorLink">всего {data.BOOKS_COUNT} {booksCountText(data.BOOKS_COUNT)} <MdArrowForwardIos /></div>
                </div>
            </NavLink>
        </div>
    )
}

export default AuthorSearchResult;