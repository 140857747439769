import React from "react";
import "./ReadmeBanner.css"
import qrArrow from "../../../img/qrArrow.svg"
import readMeQr from "../../../img/readMeQr.svg"

function ReadmeBanner(){
    return (
        <div className="s-bg">
            <div className="readMeBanner-wrapper">
                <div className="readMeBanner-content-left">
                    <div className="readMeBanner-content-title">
                        Почитай мне…<br />про любовь
                    </div>
                    <div className="readMeBanner-content-smallText">
                        Запиши книгу своим голосом<br />и подари любимому человеку<br /><br />
                        Скачай приложение и участвуй в акции
                    </div>
                    <img src={qrArrow} className="qrArrow" alt="почитай мне" />
                </div>
                <div className="readMeBanner-content-right">
                </div>
                <div className="readeMeQr">
                    <img src={readMeQr} alt="почитай мне" />
                </div>
            </div>
        </div>
    )
}

export default ReadmeBanner;