import React from "react";
import "./NotificationPanel.css";
import CookieNotification from "./CookieNotification/CookieNotification";
import AgeNotification from "./AgeNotification/AgeNotification";

function NotificationPanel(){

    return (
        <div className="notificationArea">
            <AgeNotification />
            <CookieNotification />
        </div>
    )
}

export default NotificationPanel;