import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Preview from "../../Preview/Preview";
import "../Showcase.css"
import BookContainer from "../../BookContainer/BookContainer";
import 'animate.css'
import UrlService from "../../../Services/UrlService";
import { ymReachGoal } from "../../../Functions/Functions";

function ShowCaseShelfAudio(props){

    const urlService = new UrlService();

    const [shelfData, setShelfData] = useState(null);
    const [apiRequestResult, setApiRequestResult] = useState(false);

    useEffect(() => {
        axios.get(
            urlService.getApiURL(), {
            params: {
                sId: props?.content?.identifiers[0],
                type: "shelf"
            }
        })        
        .then((data) => {
            setApiRequestResult(true);
            const responseData = data?.data?.data;
            if (!responseData || !responseData[0]?.BOOKS?.length) return false
            let shelfData = data?.data?.data[0];
            shelfData.BOOKS = shelfData?.BOOKS?.sort((a, b) => 0.5 - Math.random())
            setShelfData(shelfData);
        });
        // eslint-disable-next-line
    }, []);

    if (apiRequestResult && !shelfData?.BOOKS){
        return null;
    }

    const shelvesIterator = Array.apply(null, Array(props?.count));

    return (
        <div className="s-bg">
            <div className="showcaseRow sR-shelf">
                <div className="sR-titleRow">
                    <div className="sR-titleRow-title">
                        {shelfData ? (<h2>{shelfData.TITLE}</h2>) : <Preview type={"sR-title"} />}
                    </div>
                    <div className="sR-titleRow-href">
                        {shelfData ? <NavLink to={props?.customUrl ? props?.customUrl : "/shelf/"+props?.content?.identifiers[0]} onClick={() => ymReachGoal(props?.customGoal)} className="animate__animated animate__fadeIn">все</NavLink> : "" }
                    </div>
                </div>
                <div className="sR-contentRow">
                    {shelvesIterator.map((el, key) => (<BookContainer key={key} bookData={shelfData ? shelfData.BOOKS[key] : null} type={"audio"} />))}
                </div>
            </div>
        </div>
    )
}

export default ShowCaseShelfAudio;