import React from "react";
import "./HeaderBanner.css"
import Button from "../Button/Button";
import AppBadge from "../AppBadge/AppBadge";

function HeaderBanner(){
    return (
        <div className="header-banner">
            <div className="headerBannerWrapper s-bg">
                <div className="headerBannerWrapper-text">
                    Огромная библиотека книг в одной подписке
                </div>
                <div className="headerBannerWrapper-button">
                    <Button
                        text={"У меня есть промокод"}
                        action={"modal"} 
                        modalSrc={"downloadApp"}
                        customIcon={"giftIcon"}
                        buttonStyle={"horizontalIcon ptb-7"}
                    />
                    <Button 
                        action={"modal"} 
                        modalSrc={"downloadApp"}
                        customIcon={"qrIcon"}
                        noButton={true}
                    />
                    <AppBadge market="appStore" />
                    <AppBadge market="googlePlay" />
                </div>              
            </div>
        </div>
    )
}

export default HeaderBanner;