import React, { useState, useEffect } from "react";
import axios from "axios";
import Preview from "../../Preview/Preview";
import "../Showcase.css"
import BookContainer from "../../BookContainer/BookContainer";
import 'animate.css'
import UrlService from "../../../Services/UrlService";

function ShowCaseBooksAudio(props){

    const urlService = new UrlService();

    const [shelfData, setShelfData] = useState(null);
    const [apiRequestResult, setApiRequestResult] = useState(false);

    useEffect(() => {
        axios.get(
            urlService.getApiURL(), {
            params: {
                from: 0,
                col: props?.count,
                type: "audioBooksSort"
            }
        })        
        .then((data) => {
            setApiRequestResult(true);
            const responseData = data?.data?.data;
            if (!responseData?.length) return false
            let shelfData = data?.data?.data;
            shelfData = shelfData?.sort((a, b) => 0.5 - Math.random())
            setShelfData(shelfData);
        });
        // eslint-disable-next-line
    }, []);

    if (apiRequestResult && !shelfData){
        return null;
    }

    const shelvesIterator = Array.apply(null, Array(props?.count));
    return (
        <div className="s-bg">
            <div className="showcaseRow sR-shelf">
                <div className="sR-titleRow">
                    <div className="sR-titleRow-title">
                        {shelfData ? (<h2>{props?.content?.title}</h2>) : <Preview type={"sR-title"} />}
                    </div>
                </div>
                <div className="sR-contentRow">
                    {shelvesIterator.map((el, key) => (<BookContainer key={key} bookData={shelfData ? shelfData[key] : null} type={"audio"} />))}
                </div>
            </div>
        </div>
    )
}

export default ShowCaseBooksAudio;