import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./Shelf.css";
import UrlService from "../../Services/UrlService";
import axios from "axios";
import { booksCountText } from "../../Functions/Functions";
import ShelfItem from "../../Components/ShelfItem/ShelfItem";

function Shelf(){
    const {id} = useParams();
    const shelfId = id;

    const urlService = new UrlService();

    const [shelfData, setShelfData] = useState(null);
    const [apiRequestResult, setApiRequestResult] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(
            urlService.getApiURL(), {
            params: {
                sId: shelfId,
                type: "shelf"
            }
        })
        .then((data) => {
            setApiRequestResult(true);
            const responseData = data?.data?.data;
            if (!responseData || !responseData[0]?.BOOKS?.length) return false
            let shelfData = data?.data?.data[0];
            shelfData.BOOKS = shelfData?.BOOKS?.sort((a, b) => 0.5 - Math.random())
            setShelfData(shelfData);
        });
        // eslint-disable-next-line
    }, []);

    if (!apiRequestResult){
        return null;
    }

    if (apiRequestResult && !shelfData?.BOOKS){
        navigate("/404");
        return null;
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="page-content">
                <div className="contentWrapper">
                    <div className="s-bg">
                        <div className="shelfContent">
                            <div className="title">
                                <h1>{shelfData.TITLE}</h1>
                            </div>
                            <div className="descipriton">
                                <p>{shelfData.BOOKS?.length} {booksCountText(shelfData.BOOKS?.length)} на полке</p>
                            </div>
                            <div className="content">
                                {shelfData.BOOKS.map((el, key) => <ShelfItem  key={key} bookData={el} type={el.TYPE} /> )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Shelf;