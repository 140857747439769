import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./ShelfSearchResult.css"
import { booksCountText } from "../../Functions/Functions";
import Badges from "../Badges/Badges";
import vinil from "../../img/vinil.png"
import AdultMask from "../AdultMask/AdultMask";
import { MdArrowForwardIos } from "react-icons/md";
import UrlService from "../../Services/UrlService";
import axios from "axios";

function ShelfSearchResult(shelf){

    const data = shelf.shelfData;     

    const booksIterator = Array(4).fill(null);

    const urlService = new UrlService();

    const [shelfData, setShelfData] = useState(null);
    const [apiRequestResult, setApiRequestResult] = useState(false);

    useEffect(() => {
        axios.get(
            urlService.getApiURL(), {
            params: {
                sId: data?.id,
                type: "shelf"
            }
        })
        .then((data) => {
            setApiRequestResult(true);
            const responseData = data?.data?.data;
            if (!responseData || !responseData[0]?.BOOKS?.length) return false
            let shelfData = data?.data?.data[0];
            shelfData.BOOKS = shelfData?.BOOKS?.sort((a, b) => 0.5 - Math.random())
            setShelfData(shelfData);
        });
        // eslint-disable-next-line
    }, []);

    if (!data || (apiRequestResult && !shelfData?.BOOKS)){
        return null;
    }

    if (shelfData?.BOOKS){
        const shelfBooks = shelfData?.BOOKS?.slice(0,4);
        booksIterator.splice(0, shelfBooks.length, ...shelfBooks)
    }

    return (
        <div className="shelfSearchResultWrapper">
            <NavLink to={"/shelf/"+data.id}>
                <div className="shelfTitle">{data.title} / {data.books_count} {booksCountText(data.books_count)}</div>
                <div className="shelfBooks">
                    {
                        booksIterator.map((book, key) => (
                            <div key={key} className={`bookCover ${book?.TYPE === "audio" ? "audio" : ""}`}>
                                {book?.ID ? (
                                    <>
                                        <img src={book?.COVER200} alt={book?.TITLE} />
                                        <Badges bookData={book} />
                                        {book?.TYPE === "audio" ? (
                                            <div className="bookContainer-cover-vinil">
                                                <img alt={book?.TITLE} src={vinil} /> 
                                            </div>
                                        ) : null}                                           
                                        <AdultMask adultRating={book?.ADULT} />
                                    </>
                                ) : null}
                            </div>
                        ))
                    }
                    <div className="shelfLink">всего {data.books_count} {booksCountText(data.books_count)} <MdArrowForwardIos /></div>
                </div>
            </NavLink>
        </div>
    )
}

export default ShelfSearchResult;