import { useState } from 'react'

const useModalPanel = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
        setIsShowing(!isShowing);
    }

    return [
        isShowing,
        toggle
    ];
}

export default useModalPanel;