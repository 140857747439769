import React from "react";
import { initializeApp } from "firebase/app";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { useEffect } from 'react';
import ShowCaseShelf from "./Sections/ShowCaseShelf";
import ShowCaseShelfBig from "./Sections/ShowCaseShelfBig";
import ShowCaseBanner from "./Sections/ShowCaseBanner";
import ShowCaseBook from "./Sections/ShowCaseBook";
import ShowCaseShowcases from "./Sections/ShowCaseShowcases";
import ShowCaseShelfAudio from "./Sections/ShowCaseShelfAudio";
import ShowCaseShelfAudioBig from "./Sections/ShowCaseShelfAudioBig";
import ShowCaseAudiobook from "./Sections/ShowCaseAudiobook";
import ShowCaseBooksAudio from "./Sections/ShowCaseBooksAudio";
import ShowCaseShelfAnimatedAudio from "./Sections/ShowCaseShelfAnimatedAudio";
import { useNavigate } from "react-router-dom";
import SubsGrid from "../SubsGrid/SubsGrid";
import FAQ from "../FAQ/FAQ";
import AppBonuses from "../AppBonuses/AppBonuses";
import DownloadPanelBig from "../DownloadPanelBig/DownloadPanelBig";

const firebaseConfig = {
    apiKey: "AIzaSyDv_BJbXClWRTk5IgFlLGCNDvtDtamkc2Y",
    authDomain: "beeline-books.firebaseapp.com",
    databaseURL: "https://beeline-books.firebaseio.com",
    projectId: "beeline-books",
    storageBucket: "beeline-books.appspot.com",
    messagingSenderId: "1017047886734",
    appId: "1:1017047886734:web:f57ef78e5b421fed9692df",
    measurementId: "G-J2GHXR6QE1"
  };

function Showcase(props) {

    const navigate = useNavigate();
    const app = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(app);
    const [configData, setConfigData] = React.useState("");
  
    useEffect(() => {
      let rcData = '';
      fetchAndActivate(remoteConfig)
        .then(() => {
          rcData = getValue(remoteConfig, props.showCase);
          if (!rcData?._value?.length){
            return navigate("/404");
          }
          setConfigData(rcData._value);
        })
        .catch((err) => {
          console.log("Failed to fetch remote config", err);
        });
        // eslint-disable-next-line
    }, []);

    // remoteConfig.settings.minimumFetchIntervalMillis = 0;

    if (!configData.trim().length){
        return false;
    }

    const showCaseSection = (type, content, key) => {
        switch(type) {
            case "shelf": 
                if (key % 2 === 0 && !props.showCase==="web_main_page"){
                    return <ShowCaseShelfBig content={content} count={content?.count || 6} />;
                } else {
                    return <ShowCaseShelf content={content} count={content?.count || 7} />;
                }
            case "banner":
                return <ShowCaseBanner content={content} />
            case "book":                 
                return <ShowCaseBook content={content} />
            case "showcases":
                return <ShowCaseShowcases content={content} />
            case "shelf_audio": 
                if (key % 2 === 0 && !props.showCase==="web_main_page"){
                    return <ShowCaseShelfAudioBig content={content} count={content?.count || 6} />;
                } else {
                    return <ShowCaseShelfAudio content={content} count={content?.count || 7} />;
                }          
            case "book_audio":
                return <ShowCaseAudiobook content={content} />      
            case "books_audio":
                return <ShowCaseBooksAudio content={content} count={content?.count || 7} />;
            case "shelfAnimated_audio":
                return <ShowCaseShelfAnimatedAudio  content={content} />;
            case "promo_subscribe_banner":                 
            case "genres_audio":
                return null;
            case "subscription":
                return <div className="s-bg m-t60 m-b30"><SubsGrid /></div>;
            case "advantages":
                return <AppBonuses />;
            case "download":
                return <DownloadPanelBig />;
            case "FAQ":
                return <FAQ />;
            default: return ''
        }
    }

    let cDataArray = JSON.parse(configData);

    return (
        <React.Fragment>
            {cDataArray.map((el, key) => (
                <React.Fragment key={key}>
                    {showCaseSection(el.type, el.content, key)}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
  }    

  export default Showcase;