import React from "react";
import { NavLink } from "react-router-dom";
import "./BookContainer.css"
import Preview from "../Preview/Preview";
import bookPreloader from "../../img/bookPreloader.svg"
import { authorName } from "../../Functions/Functions";
import Badges from "../Badges/Badges";
import vinil from "../../img/vinil.png"
import AdultMask from "../AdultMask/AdultMask";

function BookContainer(bookData){

    let isAudio = bookData.type === "audio";

    const coverPreview = bookPreloader;

    if (!bookData){
        return null;
    }    

    return (
        <div className="bookContainerWrapper">
            {bookData?.bookData ? (
                <NavLink to={"/book/"+bookData?.bookData?.ID}>
                    <div className={ (isAudio ? "audioBook" : "") + " bookContainer" }>
                        <div className="bookContainer-cover animate__animated animate__fadeIn">
                            {bookData?.bookData?.COVER ? <img src={bookData?.bookData?.COVER} alt={bookData?.bookData?.TITLE}/> : <img className="coverPreview" src={coverPreview} alt=""/>}
                            <Badges bookData={bookData?.bookData} />
                            {isAudio ? (
                                <div className="bookContainer-cover-vinil">
                                    <img alt={bookData?.bookData?.TITLE} src={vinil} /> 
                                </div>
                            ) : null}
                            <AdultMask adultRating={bookData?.bookData?.ADULT} />
                        </div>
                        <div className="bookContainer-title">
                            {bookData?.bookData?.TITLE ? bookData?.bookData?.TITLE : <Preview type={"sR-bookTitle"} />}
                        </div>
                        <div className="bookContainer-author">
                            {bookData?.bookData?.AUTHORS ? authorName(bookData?.bookData?.AUTHORS) : <Preview type={"sR-bookAuthor"} lines={2} />}
                        </div>
                    </div>
                </NavLink>
            ) : null}
        </div>
    )
}

export default BookContainer;