import axios from "axios";
import errorCat1 from "../img/error.svg";
import UrlService from "./UrlService";

class SubscriptionService{

    urlService = new UrlService();

    subscriptions = [
        {
            name: "all",
            title: "все вместе",
            subTitle: "электронные книги + аудиокниги",
            prices: {
                d: 20,
                m: 399,
                up: 100
            }
        },
        {
            name: "books",
            title: "книги",
            subTitle: "только электронные книги.\nаудиокниги не входят в подписку",
            prices: {
                d: 12,
                m: 299,
                up: 299
            }
        },
        {
            name: "audio",
            title: "Аудиокниги",
            subTitle: "В качественной озвучке — для полного погружения в сюжет",
            prices: {
                d: 18,
                m: 350,
                up: 350
            }
        },
        {
            name: "promo",
            title: "«книги» за 299 руб. в месяц + 45 дней чтения в подарок",
            prices: {
                d: 20,
                m: 299,
                up: 299
            },
            rulesSrc: `${this.urlService.getProdUrl()}/rules/45-299.html`,
            lawText: `Платеж спишется автоматически с выбранного способа оплаты: мобильный счет или счёт банковской карты (для проверки карты мы спишем и вернем 10 руб.). Вы сможете остаться на подписке «книги» за 299 руб. без аудиокниг или подключить подписку «книги и аудиокниги» за 399 руб. в месяц. Бесплатный период на подписку «книги» в соответствии с Правилами может быть предоставлен не позднее чем 1 ноября 2025 г. включительно, а срок бесплатного доступа к подписке «Книги» не может быть дольше, чем 45 дней с даты завершения Акции. Для абонентов всех операторов.\nАктивация может занять до 10 минут.\nОрганизатор акции: ПАО «ВымпелКом». Срок акции с 15.03.25 г. по 01.11.25 г. Акция может быть прекращена досрочно по решению Организатора акции, 18+`
        }

    ]


    // 9998 - не билайновский пользователь
    // 001 в конце - статус для промо-подписки
    subscriptionErrors = [
        {
            codes: [9998],
            title: "Ой, мы пока не можем подключить подписку",
            subtitle: "Совсем скоро она станет доступна для всех",
            image: errorCat1
        },
        {
            codes: [500001, 2550001],
            title: "Упс... Не можем активировать промо-период",
            subtitle: "Предложение по акции не доступно пользователям с активной подпиской",
            image: errorCat1
        },
        {
            codes: [500, 2550],
            title: "Упс... Не можем активировать подписку",
            subtitle: "У Вас уже есть активная подписка",
            image: errorCat1
        },
        {
            codes: [400],
            title: "Что-то пошло не по плану",
            subtitle: "Запрос заблудился в матрице, но мы его уже ищем. Попробуйте ещё раз",
            image: errorCat1
        },
    ]

    unknownSubscriptionError = {
        title: "Что-то пошло не по плану",
        subtitle: "Запрос заблудился в матрице, но мы его уже ищем. Попробуйте ещё раз",
        image: errorCat1
    }

    getErrorInfo(errorCode){
        let error = this.subscriptionErrors.find(error => error.codes.includes(Number(errorCode)));
        !error && (error = this.unknownSubscriptionError);
        return error;
    }

    getSubscriptionInfo(type){
        return this.subscriptions.find(sub => sub.name === type);
    }

    getSubscriptions(){
        return this.subscriptions;
    }

    createSubscription(subscriptionData){

        console.log(subscriptionData);

        const urls = this.urlService.getSubscriptionUrl();
        let url = urls.base;

        if (subscriptionData?.subsType === "promo"){
            url = urls.promo;
        }

        let preparedData = {
            tcsb: (subscriptionData.paymentType === 0) ? false : true,
            tString: subscriptionData.userToken,
            channel: subscriptionData.channel,
            plan: (subscriptionData.selectedPeriod === "d") ? 1 : 2,
            type: subscriptionData.subsType,
            isBeelineUser: subscriptionData.userIsBee			
        }
        if (subscriptionData.subsType === "promo"){
            preparedData.promo = true;
        }
        if (subscriptionData.tarifUp){
            preparedData.tarifUp = true;
        }

        const form = new FormData()
        Object.entries(preparedData).forEach(([key, value]) => form.append(key, value))    
        
        // console.log(preparedData);

        return axios.post(url, form);
    }

    checkCardPayment(paymentInfo){
        const url = this.urlService.getCheckingUrl();

        let preparedData = { 
            token: paymentInfo.userToken,
            plan: (paymentInfo.selectedPeriod === "d") ? 1 : 2,
            type: paymentInfo.subsType
        }
        if (paymentInfo.subsType === "promo"){
            preparedData.promo = "bau299";
        }

        const form = new FormData()
        Object.entries(preparedData).forEach(([key, value]) => form.append(key, value))   

        return axios.post(url, form);
    }

    checkBookSubs(bookId){
        const url = this.urlService.getApiURL();
        return axios.get(`${url}?type=subsList&id=${bookId}`);
    }

    checkPurchaseStatus(params){
        const url = this.urlService.getApiURL();
        return axios.get(`${url}?type=purchaseBookStatus&bId=${params.bId}&uToken=${params.uToken}`);
    }

    purchaseBook(params){
        const url = this.urlService.getApiURL();
        return axios.get(`${url}?type=purchaseBook&bId=${params.bId}&uToken=${params.uToken}`);
    }
}

export default SubscriptionService;