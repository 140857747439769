import React from "react";
import "./Decore.css"
import decoreRight from "../../img/cloud_right.svg";
import decoreLeft from "../../img/cloud_left.svg";

function Decore(){
    return (
        <>
            <div className="newYear2024-left">
                <img src={decoreLeft} alt="книги билайн" />
            </div>
            <div className="newYear2024-right">
                <img src={decoreRight} alt="книги билайн" />
            </div>
        </>
    )
}

export default Decore;