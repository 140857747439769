import React from "react";
import "./SubsInfo.css";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import SubsGrid from "../../Components/SubsGrid/SubsGrid";
import FAQitem from "../../Components/FAQ/FAQitem/FAQitem";
import bonusImg1 from "../../img/booksLine.png"
import bonusImg2 from "../../img/plane.svg"
import bonusImg3 from "../../img/catinf.svg"
import bonusImg4 from "../../img/genreslines.png"

function SubsInfo(){
    return (
        <div className="page-wrapper">
            <Header />
            <div className="page-content">
                <div className="contentWrapper">
                    <div className="subsInfo">
                        <div className="bonusesWrapper">
                            <div className="s-bg">
                                <div className="bonuses">
                                    <div className="bonusesItem">
                                        <div className="topLine">
                                            <h3>Тысячи книг<br/>и аудиокниг</h3>
                                            <p>Доступ ко всей<br/>библиотеке</p>
                                        </div>
                                        <div className="bottomLine centeredImg">
                                            <img src={bonusImg1} className="bonusImg1" alt="книги билайн" />
                                        </div>
                                    </div>
                                    <div className="bonusesItem">
                                        <div className="topLine centeredImg">
                                            <img src={bonusImg2} className="bonusImg2" alt="книги билайн" />
                                        </div>
                                        <div className="bottomLine">
                                            <h3>Офлайн-<br/>доступ</h3>
                                            <p>Читайте и слушайте<br/>скачанные книги — даже<br/>когда нет интернета</p>
                                        </div>
                                    </div>
                                    <div className="bonusesItem">
                                        <div className="topLine">
                                            <h3>Синхронизация<br/>прогресса</h3>
                                            <p>Продолжайте слушать там, где закончили читать</p>
                                        </div>
                                        <div className="bottomLine centeredImg">
                                            <img src={bonusImg3} className="bonusImg3" alt="книги билайн" />
                                        </div>
                                    </div>
                                    <div className="bonusesItem">
                                        <div className="topLine centeredImg">
                                            <img src={bonusImg4} className="bonusImg4" alt="книги билайн" />
                                        </div>
                                        <div className="bottomLine">
                                            <h3>Новинки<br/>и бестселлеры</h3>
                                            <p>Пополняем библиотеку каждый месяц</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="s-bg">
                            <div className="titleRow">
                                <h2>Выберите свою подписку</h2>
                            </div>
                            <SubsGrid />
                            <div className="FAQ-wrapper">
                                <h2>Условия предоставления услуги</h2>
                                <div className="description">
                                    <ul>
                                        <li>Списание ежедневное с автоматическим продлением.</li>
                                        <li>Месяц подписки - это 30 календарных дней, продлевается автоматически.</li>
                                        <li>Управлять доступом можно в разделе "Профиль"</li>
                                    </ul>
                                </div>
                                <FAQitem title="Где и как подключить услугу?">
                                    <p>Подключить услугу можно в приложениях билайн и книги билайн, а также на сайте книги билайн в личном кабинете.</p>
                                </FAQitem>
                                <FAQitem title="Нужно ли продлевать подписку?">
                                <p>После выбора подходящей подписки оплатить можно следующими способами:</p>
                                <ul>
                                    <li>со счёта билайн, если вы абонент билайн;</li>
                                    <li>с банковской карты и через Систему быстрых платежей, если вы абонент не билайн.</li>
                                </ul>
                                <p>Для абонентов билайн и при оплате банковской картой подписка продлевается автоматически каждый месяц.</p>
							    <p>При оплате через Систему быстрых платежей для повторного предоставления доступа необходимо произвести оплату.</p>
                                </FAQitem>
                                <FAQitem title="Действует ли подписка, если её отключить?">
                                <p>После отключения оплаченной подписки вы можете пользоваться услугой до конца оплаченного периода.</p>
                                </FAQitem>
                                <FAQitem title="Где и как отключить услугу?">
                                    <p>Отключить услугу можно в приложении билайн и приложении книги и аудиокниги билайн, а также на сайте билайн в личном кабинете.</p>
                                </FAQitem>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SubsInfo;