import React from "react";
import Header from "../../Components/Header/Header";
import Showcase from "../../Components/Showcase/Showcase";
import Footer from "../../Components/Footer/Footer";
import { useParams } from "react-router-dom";

function CustomShowcase(){

    const {sc} = useParams();

    return (
        <div className="page-wrapper">
            <Header />
            <div className="page-content">
                <div className="contentWrapper s-bg">
                    <Showcase showCase={`showcase_${sc}`}/>
                </div>
            </div>
            <Footer />
        </div>
      );
}

export default CustomShowcase;