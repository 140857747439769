import React, { useEffect, useState } from 'react';
import './App.css';
// import Showcase from './Components/Showcase/Showcase'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Books from './Pages/Books/Books';
import Audio from './Pages/Audio/Audio';
import Main from './Pages/Main/Main';
import Book from './Pages/Book/Book';
import NotFound from './Pages/NotFound/NotFound';
import BooksFree from './Pages/BooksFree/BooksFree';
import BooksPaid from './Pages/BooksPaid/BooksPaid';
import Shelf from './Pages/Shelf/Shelf';
import Search from './Pages/Search/Search';
import { AppContext } from './Hooks/appContext';
import UrlService from './Services/UrlService';
import axios from 'axios';
import User from './Pages/User/User';
import SubsInfo from './Pages/SubsInfo/SubsInfo';
import Purchase from './Pages/Purchase/Purchase';
import { logOut } from './Functions/Functions';
import { HelmetProvider } from 'react-helmet-async';
import { YMInitializer } from 'react-yandex-metrika';
import Policy from './Pages/Policy/Policy';
import Author from './Pages/Author/Author';
import Offer from './Pages/Offer/Offer';
import Bloggers from './Pages/Bloggers/Bloggers';
import AppPage from './Pages/AppPage/AppPage';
import AppsPage from './Pages/AppsPage/AppsPage';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import CustomShowcase from './Pages/CustomShowcase/CustomShowcase';

function App() {

  const urlService = new UrlService();
  const baseUrl = urlService.getServiceUrl();

  const [userLoggedInCtx, setUserLoggedInCtx] = useState(false);
  const [userTokenCtx, setUserTokenCtx] = useState(null);
  const [userMsisdnCtx, setUserMsisdnCtx] = useState(null);
  const [userImageCtx, setUserImageCtx] = useState(`${baseUrl}/images/beeline_logo_ball.svg`);
  const [userIsBeeCtx, setUserIsBeeCtx] = useState(true);
  const [userSubscriptionsCtx, setUserSubscriptionsCtx] = useState(null);
  const [userBlockedAdultCtx, setUserBlockedAdultCtx] = useState(true);
  const [loadedCtx, setLoadedCtx] = useState(false);
  const [adultBannerShownCtx, setAdultBannerShownCtx] = useState(false);

  useEffect(() => {
    userTokenCtx && axios.get(
      urlService.getApiURL(), {
      params: {
        uToken: userTokenCtx,
        type: "checkUser"
      }
    })
    .then((data) => {
        if (data?.data?.success){
          if (!data?.data?.data){
            const lastSymbol = userMsisdnCtx.slice(-1);
            setUserImageCtx(`${baseUrl}/images/avatars/${(lastSymbol % 9)+1}.png`);
            setUserIsBeeCtx(false);
          } else {
            setUserImageCtx(`${baseUrl}/images/beeline_logo_ball.svg`);
            setUserIsBeeCtx(true);
          }
        } else {
          logOut();
        }
    })
    .catch((e) => {
      return null;
    })
    setLoadedCtx(true);
  // eslint-disable-next-line
  }, [userMsisdnCtx])

  useEffect(() => {

    if (localStorage.getItem('user')){
      const userData = JSON.parse(localStorage.getItem('user'));
      if (userData?.token && userData?.msisdn){
        setUserTokenCtx(userData?.token);
        setUserMsisdnCtx(userData?.msisdn);
        setUserLoggedInCtx(true);
      }

      axios.get(
        urlService.getUserSubs(), {
        params: {
          uToken: userData?.token,
        }
      })
      .then((data) => {
        data?.data && setUserSubscriptionsCtx(data.data);
      })
      .catch((e) => {
        return null;
      })      
    }

    const blockedAdult = localStorage.getItem("bbooks_blockedAdult");
    if ((blockedAdult !== null) && (Number(blockedAdult) === 0)){
      setUserBlockedAdultCtx(false);
    }
  // eslint-disable-next-line
  }, []);

  function toggleUserBlockedAdultCtx(){
    setUserBlockedAdultCtx(false);
  }

  const showAdultBannerCtx = (val) => {
    setAdultBannerShownCtx(val);
  }

  const contextValue = {
    userLoggedInCtx,
    userTokenCtx,
    userMsisdnCtx,
    userImageCtx,
    userIsBeeCtx,
    userSubscriptionsCtx,
    userBlockedAdultCtx,
    toggleUserBlockedAdultCtx,
    loadedCtx,
    showAdultBannerCtx,
    adultBannerShownCtx
  };  

  return (
    <AppContext.Provider value={contextValue}>
      <React.Fragment>
        <YMInitializer accounts={[53666791]} options={{ clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true }} version="2" />
        <HelmetProvider>
          <Router
            future={{
              v7_relativeSplatPath: true,
              v7_startTransition: true
            }}
          >
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="books/" element={<Books />} />
              <Route path="books/free/" element={<BooksFree />} />
              <Route path="books/paid/" element={<BooksPaid />} />
              <Route path="books/:sc" element={<CustomShowcase />} />
              <Route path="audio/" element={<Audio />} />
              <Route path="book/" element={<Navigate to="/" />} />
              <Route path="book/:id" element={<Book />} />
              <Route path="search/*" element={<Search />} />          
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
              <Route path="shelf/" element={<Navigate to="/" />} />
              <Route path="shelf/:id" element={<Shelf />} />
              <Route path="user/" element={<Navigate to="/" />} />
              <Route path="user/edit/" element={<User />} />
              <Route path="subsinfo" element={<SubsInfo />} />
              <Route path="purchase/" element={<Navigate to="/" />} />
              <Route path="purchase/:id" element={<Purchase />} />
              <Route path="policy" element={<Policy />} />
              <Route path="author/" element={<Navigate to="/" />} />
              <Route path="author/:id" element={<Author />} />
              <Route path="offer/" element={<NotFound />} />
              <Route path="offer/:id" element={<Offer />} />
              <Route path="bloggers/" element={<NotFound />} />
              <Route path="bloggers/:id" element={<Bloggers />} />
              <Route path="app/" element={<AppPage />} />
              <Route path="app/:id" element={<AppPage />} />
              <Route path="apps/" element={<AppsPage />} />
              <Route path="apps/:id" element={<AppsPage />} />
            </Routes>
          </Router>
        </HelmetProvider>
      </React.Fragment>
    </AppContext.Provider>
  );
}

export default App;
