import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './SwiperCentered.css';
import ButtonSecondary from "../../ButtonSecondary/ButtonSecondary";
import stripHtml, { authorName } from "../../../Functions/Functions";
import AdultMask from "../../AdultMask/AdultMask";


function SwiperCentered(props){

    if (!props?.shelfBooks?.BOOKS || !props?.shelfBooks?.BOOKS?.length){
        return null;
    }

    const sliderBooks = props?.shelfBooks?.BOOKS;

    return (
        <React.Fragment>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={50}
                speed={750}
                // navigation={{
                //     nextEl: '.SwiperCentered-next',
                //     prevEl: '.SwiperCentered-prev',
                // }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}                
                modules={[Pagination, Navigation, Autoplay]}
                className="swiperCentered"
            >
                {sliderBooks.map((book, key) => (
                    <SwiperSlide key={key}>
                        <div className="SliderCentered-item">
                            <div className="SliderCentered-wrapper">
                                <div className="SliderCentered-cover">
                                    <img alt={book.TITLE} src={book.COVER200} />
                                    <AdultMask adultRating={book.ADULT} />
                                </div>
                                <div className="SliderCentered-content">
                                    <div className="SliderCentered-content-title">
                                        {book.TITLE}
                                    </div>
                                    <div className="SliderCentered-content-author">
                                        {authorName(book.AUTHORS)}
                                    </div>
                                    <div className="SliderCentered-content-categories">
                                        {book.CATEGORIES?.map((cat, k) => (
                                            <div key={k} className="SliderCentered-content-categories-item">{cat}</div>
                                        ))}
                                    </div>
                                    <div className="SliderCentered-content-description">
                                        {stripHtml(book.ANNOTATION)}
                                    </div>
                                    <div className="SliderCentered-content-button">
                                        <ButtonSecondary text={"подробнее"} action={"url"} href={"/book/"+book.ID} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </React.Fragment>
      );    
}

export default SwiperCentered;
