import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom"
import "./ModalPanel.css"
import { IoClose } from "react-icons/io5";

const ModalPanel = ({ show, onCloseButtonClick, content }) => {

    const modalBack = useRef(null);

    useEffect(() =>{
        if (!show) {return};

        document.body.addEventListener('mousedown', closeModalOnClickOut)
        return () => {
            document.body.removeEventListener('mousedown', closeModalOnClickOut)
        }
    // eslint-disable-next-line        
    }, [show]);

    useEffect(() =>{
        if (!show) {return};

        document.body.addEventListener('keydown', closeModalonEscKeyDown)
        return () => {
            document.body.removeEventListener('keydown', closeModalonEscKeyDown)
        }
    // eslint-disable-next-line
    }, [show])     

    const closeModalOnClickOut = (e) => {
        if (show && e.target && modalBack.current && !modalBack.current.contains(e.target)){
            onCloseButtonClick();
        }
    }    

    const closeModalonEscKeyDown = (e) => {
        if (show && e.code === 'Escape'){
            onCloseButtonClick();
        }
    }    

    if (!show) {
      return null;
    }
  
    return ReactDOM.createPortal(
        <div className="modal-backdrop">
            <div ref={modalBack} className="modal-content">
                <div className="modal-body">{content}</div>
                <div className="modal-closeButton" onClick={onCloseButtonClick}>
                    <IoClose />
                </div>
            </div>
        </div>
        , document.body
    );
  };
  
  export default ModalPanel;