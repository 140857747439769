import React, {useContext} from "react";
import adultBadge18 from "../../img/18.svg"
import "./AdultMask.css"
import { AppContext } from "../../Hooks/appContext";

function AdultMask(props){

    const isActive = true;

    const { userBlockedAdultCtx, showAdultBannerCtx } = useContext(AppContext);

    const adultRating = props?.adultRating;

    if(adultRating < 18 || !userBlockedAdultCtx){
        return null;
    }

    function showAdultModal(event){
        event.preventDefault();
        showAdultBannerCtx(true);
    }

    return (
        isActive ? (
        <div className="adultMask" onClick={(e) => showAdultModal(e)}>
            <img src={adultBadge18} alt="18+" />
        </div>
        ) : null
    )
}

export default AdultMask;