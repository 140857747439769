import React from "react";
import "./FAQ.css"
import FAQitem from "./FAQitem/FAQitem";

function FAQ(){
    return (
        <div className="s-bg">
            <div className="FAQ-wrapper">
                <h2>Частые вопросы</h2>
                <FAQitem title="Что такое книги билайн?">
                    <p>книги билайн – это сотни тысяч книг и аудиокниг ведущих издательств в любое время и в любом месте. Читайте и слушайте любимые произведения со смартфона, планшета или компьютера.</p>
                    <p>Удобная читалка для электронных текстовых книг с множеством настроек поддерживает все популярные книжные форматы. Добавляйте книги в свою библиотеку, оставляйте закладки и делайте пометки. </p>
                    <p>Найти книгу в приложении можно не только вручную, но и при помощи голосового ввода запроса в строку поиска.</p>
                    <p>В аудиоплеере можно оставлять закладки, менять скорость воспроизведения. </p>
                    <p>Засыпать под аудиокниги стало ещё удобнее – таймер сна остановит проигрывание, нужно лишь выбрать время в настройках.</p>
                    <p>Доступна синхронизация электронной и аудиокниг: переключайтесь на удобный формат в любой момент. </p>
                    <p>Вы можете читать и слушать книги на смартфоне даже без подключения к интернету.</p>
                    <p>В сервисе доступен бесплатный каталог, есть возможность загружать собственные книги и читать бесплатно в ридере. </p>
                    <p>Для удобства пользователей реализованы разные модели подписки и покупки конкретных книг.</p>
                </FAQitem>
                <FAQitem title="Можно ли слушать в автомобиле?">
                    <p>Да, аудиокниги можно слушать в автомобиле - приложение билайн книги и аудиокниги поддерживает Apple CarPlay и AndroidAuto. Запускайте проигрывание, перемотку и переключение глав прямо с экрана бортового компьютера.</p>
                </FAQitem>
                <FAQitem title="Как авторизоваться в книгах билайн?">
                    <p>Авторизоваться в книгах билайн можно в два клика - по mobile ID или через код в SMS. Оба способа доступны для абонентов всех операторов сотовой связи.</p>
                </FAQitem>
                <FAQitem title="Какие виды подписок есть?">
                    <ul>
                        <li><b>Подписка «Книги»</b> для тех, кто любит читать текст глазами. Она открывает доступ к тысячам книг онлайн и офлайн. Аудиокниги по данной подписке не представлены, что позволяет удерживать очень демократичную цену.</li>
                        <li><b>Подписка «Аудиокниги»</b> для тех, кто обычно слушает книги и не хочет платить за лишнее. В ней открыт весь аудио каталог онлайн и офлайн.</li>
                        <li><b>Подписка «Книги и аудиокниги»</b> - для тех, кому важно иметь под рукой все форматы. Доступен текстовый и аудиокаталог онлайн и офлайн – по самой оптимальной цене. Удобно пользоваться, переключаясь между форматами в зависимости от обстоятельств.</li>
                    </ul>
                    <p>При подключении новой подписки не забудьте отключить старую.</p>
                    <p>Можно читать и слушать без подписки, покупая книги поштучно.</p>
                    <p>Всем авторизованным пользователям доступен бесплатный каталог.</p>
                </FAQitem>
                <FAQitem title="Какие есть периоды оплаты подписки?">
                    <p>Выбрать подписку с подходящим периодом оплаты можно в профиле приложения «билайн книги и аудиокниги» или на сайте: </p>
                    <ul>
                        <li>Ежемесячная (доступна абонентам любых сотовых операторов) - абонентская плата списывается 1 раз в месяц через 30 календарных дней. Идеально для тех, кто с книгами всерьез и надолго. Оплачивается с привязанной банковской карты и с абонентского счета билайн.</li>
                        <li>Ежедневная (доступна только абонентам билайн) - абонентская плата списывается 1 раз в день. Удобно подключить на время отпуска, чтобы погрузиться в книгу на несколько дней или недель. Оплачивается с мобильного счета абонента.</li>
                    </ul>
                </FAQitem>
                <FAQitem title="Как управлять подпиской?">
                    <p>Подключить и отключить подписку можно на сайте или в приложении билайн в личном кабинете, а также в приложении книги билайн. После отключения оплаченной подписки вы можете пользоваться услугой до конца оплаченного периода. В рекламных предложениях могут быть также и другие способы отключения и подключения услуги.</p>
                </FAQitem>
            </div>
        </div>
    )
}

export default FAQ;