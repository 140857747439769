import React, { useContext } from "react";
import './Footer.css'
import ageIcon from "../../img/footer_18.svg";
import bwLogo from "../../img/beeline-icon.svg";
import { NavLink } from "react-router-dom";
import NotificationPanel from "../NotificationPanel/NotificationPanel";
import UrlService from "../../Services/UrlService";
import ModalPanel from "../ModalPanel/ModalPanel";
import useModalPanel from "../../Hooks/useModalPanel";
import TopButton from "../TopButton/TopButton";
import Decore from "../Decore/Decore";

import ModalDownloadApp from "../ModalPanel/ModalContent/ModalDownloadApp/ModalDownloadApp";
import { AppContext } from "../../Hooks/appContext";
import ModalAdult from "../ModalPanel/ModalContent/ModalAdult/ModalAdult";

function Footer(){
    
    const urlService = new UrlService();

    const [isShowingModal, toggleModal] = useModalPanel();

    const {adultBannerShownCtx, showAdultBannerCtx} = useContext(AppContext);

    return (
        <React.Fragment>
            <div className="page-footer">
                <div className="footerWrapper s-bg">
                    <div className="footer-column-1">
                        <p><a href="mailto:helpdesk@in-book.ru">Связаться с нами</a></p>
                    </div>
                    <div className="footer-column-2">
                        <p><a target="_blank" rel="noopener noreferrer" href="https://moskva.beeline.ru/customers/products/mobile/services/details/beeline-knigi/">О сервисе</a></p>
                        <p><NavLink target="_blank" to="/policy">Пользовательское соглашение</NavLink></p>
                        <div className="footer-copyright">
                            <img src={ageIcon} alt="18+"/> © 2025 книги билайн
                        </div>
                    </div>
                    <div className="footer-column-3">
                        <h3>книги<img src={bwLogo} alt="книги билайн" />билайн</h3>
                        <div className="footer-badges">
                            <a href={urlService.getAppLink("googlePlay")} target="_blank" rel="noreferrer"><div className="footer_android"></div></a>
                            <a href={urlService.getAppLink("appStore")} target="_blank" rel="noreferrer"><div className="footer_apple"></div></a>
                            <a href={urlService.getAppLink("appGallery")} target="_blank" rel="noreferrer"><div className="footer_huawei"></div></a>
                            <a href={urlService.getAppLink("ruStore")} target="_blank" rel="noreferrer"><div className="footer_rustore"></div></a>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationPanel />
            <ModalPanel show={isShowingModal} onCloseButtonClick={toggleModal} content={<ModalDownloadApp />}/>
            <ModalPanel show={adultBannerShownCtx} onCloseButtonClick={showAdultBannerCtx} content={<ModalAdult />}/>
            <TopButton />
            <Decore />
        </React.Fragment>
    )
}

export default Footer;