import axios from "axios";
import UrlService from "./UrlService";

class SearchService{

    urlService = new UrlService();

    getResources(keyword){
        const url = this.urlService.getApiURL();
        return axios.get(`${url}?type=searchObject&keyword=${keyword}`);
    }

    getObject(resId){
        const url = this.urlService.getApiURL();
        return axios.get(`${url}?type=searchResource&resId=${resId}`);
    }
}

export default SearchService;