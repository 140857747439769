import React, { useContext, useLayoutEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "./User.css";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../Hooks/appContext";
import { useNavigate } from "react-router-dom";
import catSearch from "../../img/cat_search.svg";
import axios from "axios";
import UrlService from "../../Services/UrlService";
import BookContainer from "../../Components/BookContainer/BookContainer";
import SubsGrid from "../../Components/SubsGrid/SubsGrid";

function User(){

    const [selectedTab, setSelectedTab] = useState("books");
    const {userLoggedInCtx, userTokenCtx } = useContext(AppContext);
    const [userLoggedInLoaded, setUserLoggedInLoaded] = useState(false);

    const [userBoughtBooks, setUserBoughtBooks] = useState({data: [], loaded: false});
    const [userFavBooks, setUserFavBooks] = useState({data: [], loaded: false});

    const urlService = new UrlService();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        setTimeout(() => {
            setUserLoggedInLoaded(true);
        }, 0)
    }, [userLoggedInCtx]);

    useLayoutEffect(() => {

        userLoggedInLoaded && axios.get(
            urlService.getApiURL(), {
            params: {
                type: "syncState",
                uToken: userTokenCtx
            }
        })
        .then((resp) => {
            if (!resp.data?.success){
                setUserFavBooks({data: false, loaded: true});
                return false;
            }
            const favBooks = resp?.data?.data.filter(book => book.status === "READ").sort(function(a,b){
                return b.status_update_date - a.status_update_date;
            });

            let bookIds = [];
            favBooks.map(book => {
                bookIds.push(book.book_id);
                return book;
            })

            axios.get(
                urlService.getApiURL(), {
                params: {
                    type: "booksList",
                    uToken: userTokenCtx,
                    bookIds: JSON.stringify(bookIds)
                }
            }).then((booksList) => {
                if (booksList?.data?.data?.length){
                    setUserFavBooks({data: booksList?.data?.data, loaded: true})    
                } else {
                    setUserFavBooks({data: false, loaded: true})    
                }
            })
        });

        userLoggedInLoaded && axios.get(
            urlService.getApiURL(), {
            params: {
                type: "purchased",
                uToken: userTokenCtx
            }
        })
        .then((purchasedBooks) => {
            const purchasedData = purchasedBooks.data?.success ? {data: purchasedBooks.data?.data, loaded: true} : {data: false, loaded: true};
            setTimeout(() => {
                setUserBoughtBooks({data: [], loaded: false});
            }, 0);
            if (purchasedData?.data?.length){
                purchasedData?.data.forEach(bookId => {
                    axios.get(
                        urlService.getApiURL(), {
                        params: {
                            id: bookId,
                            type: "book",
                            uToken: userTokenCtx
                        }
                    })
                    .then((bookData) => {
                        let booksData = userBoughtBooks?.data;
                        booksData.push(bookData?.data?.data);
                        setTimeout(() => {
                            setUserBoughtBooks({data: booksData, loaded: booksData.length >= purchasedData?.data?.length});
                        }, 0)
                    });
                })
            }
        });

        // eslint-disable-next-line
    }, [userLoggedInLoaded]);

    function changeTab(tabName){
        setSelectedTab(tabName);
    }

    function emptyBooksResult(){
        return (
            <div className="emptyBooks">
                <div className="emptyImg">
                    <img src={catSearch} alt="ничего не найдено" />
                </div>
                <div className="emptyText">
                    Пока еще не добавлено ни одной книги.<br />Давайте это скорее исправим!
                </div>
            </div>
        )
    }

    if (userLoggedInLoaded && !userLoggedInCtx){
        navigate('/')
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="page-content">
                <div className="contentWrapper">
                    <div className="s-bg">
                        <div className="userContent">
                            <div className="userNavigation">
                                <div className={"navigationItem" + (selectedTab === "books" ? " active" : "")} onClick={() => changeTab("books")} >
                                    Мои книги
                                </div>
                                <div className={"navigationItem" + (selectedTab === "subscription" ? " active" : "")} onClick={() => changeTab("subscription")} >
                                    Подписка
                                </div>
                            </div>
                            { selectedTab === "books" ? (
                                <div className="userBooks">
                                    <div className="title">
                                        <h2>Купленные книги <span>{userBoughtBooks.loaded ? userBoughtBooks?.data?.length : "0"}</span></h2>
                                    </div>
                                    <div className="booksArea">
                                        { userBoughtBooks.loaded ? (
                                            userBoughtBooks?.data?.length ? (
                                                userBoughtBooks?.data.map((bookData, key) => <BookContainer key={key} bookData={bookData} type={bookData.TYPE} /> )
                                            ) : (
                                                emptyBooksResult()
                                            )
                                        ) : (
                                            <div className="bb_spinner medium"></div>
                                        ) }
                                    </div>
                                    <div className="title">
                                        <h2>Отложенные книги <span>{userFavBooks.loaded ? userFavBooks?.data?.length : "0"}</span></h2>
                                    </div>
                                    <div className="booksArea">
                                        {userFavBooks?.data?.length ? (
                                            userFavBooks?.data.map((bookData, key) => <BookContainer key={key} bookData={bookData} type={bookData.TYPE} /> )
                                        ) : emptyBooksResult()}                                        
                                    </div>
                                </div>
                            ) : selectedTab === "subscription" ? (
                                <div className="userSubscription">
                                    <SubsGrid />
                                    <div className="subscriptions-bottomText">
                                        Отключить подписку Вы можете в <NavLink target="_blank" to={"https://my.beeline.ru/"} >«Личном кабинете Билайна»</NavLink>
                                    </div>
                                </div>
                            ) : null }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default User;