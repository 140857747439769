import React, { useContext } from "react";
import "./ModalAdult.css";
import { AppContext } from "../../../../Hooks/appContext";
import Button from "../../../Button/Button";
import adultCat from "../../../../img/cat18.svg"

function ModalAdult(){

    const {showAdultBannerCtx, toggleUserBlockedAdultCtx} = useContext(AppContext);

    function CloseNotification(accepted){
        if (accepted){
            localStorage.setItem('bbooks_adultNotifyerShown', 1);
            localStorage.setItem('bbooks_blockedAdult', 0);
            toggleUserBlockedAdultCtx();
        }
        showAdultBannerCtx(false);
    }
    
    
    return (
        <div className="modalAdult">
            <img src={adultCat} alt="18+" />
            <h2>Вам уже есть 18 лет?</h2>
            <p>Книга имеет возрастные ограничения</p>
            <div className="buttonsArea">
                <Button text="Да, мне есть 18 лет" buttonStyle="thin w-fw ptb-15" onClick={() => CloseNotification(true)}/>
                <Button text="Нет, отменить"  buttonStyle="thin lightGrey w-fw ptb-15"  onClick={() => CloseNotification(false)}/>
            </div>
        </div>
    )
}

export default ModalAdult;