import ym from 'react-yandex-metrika';

function stripHtml(html){
    if (!html){
        return null;
    }
    let newHtml =  html?.replaceAll("</p><p>", " ")
    let tmp = document.createElement("DIV");
    tmp.innerHTML = newHtml;
    return tmp.textContent || tmp.innerText || "";
}

function authorName(authors){
    if (!authors){
        return null;
    }
    if (authors?.length > 1){
        authors = authors.filter(author => author.RELATION === "0");
    }
    let author = authors[0];
    return author ? (author.FIRST_NAME ? author.FIRST_NAME+" " : "") + "" + (author.LAST_NAME ? author.LAST_NAME : "") : null;
}

function booksCountText(count){
    const cnt = Number(count);
    if (cnt >= 11 && cnt <=14){
        return "книг";
    }
    if (cnt % 10 === 1){
        return "книга";
    }
    if (cnt % 10 >= 2 && cnt % 10 <= 4){
        return "книги";
    }
    return "книг";
}

function authorCountText(count){
    const cnt = Number(count);
    if (cnt >= 11 && cnt <=14){
        return "авторов";
    }
    if (cnt % 10 === 1){
        return "автор";
    }
    if (cnt % 10 >= 2 && cnt % 10 <= 4){
        return "автора";
    }
    return "авторов";
}

function shelfCountText(count){
    const cnt = Number(count);
    if (cnt >= 11 && cnt <=14){
        return "полок";
    }
    if (cnt % 10 === 1){
        return "полка";
    }
    if (cnt % 10 >= 2 && cnt % 10 <= 4){
        return "полки";
    }
    return "полок";
}

function logOut(){
    localStorage.clear();
    window.location.reload();
}

function ymReachGoal(goal){
    ym('reachGoal', goal);
}

function transliterate(word){
    const a = {" ":"_",'а':'a','б':'b','в':'v','г':'g','д':'d','е':'e','ё':'e','ж':'zh','з':'z','и':'i','й':'y','к':'k','л':'l','м':'m','н':'n','о':'o','п':'p','р':'r','с':'s','т':'t','у':'u','ф':'f','х':'h','ц':'c','ч':'ch','ш':'sh','щ':'sch','ь':'','ы':'y','ъ':'','э':'e','ю':'yu','я':'ya','А':'A','Б':'B','В':'V','Г':'G','Д':'D','Е':'E','Ё':'E','Ж':'Zh','З':'Z','И':'I','Й':'Y','К':'K','Л':'L','М':'M','Н':'N','О':'O','П':'P','Р':'R','С':'S','Т':'T','У':'U','Ф':'F','Х':'H','Ц':'C','Ч':'Ch','Ш':'Sh','Щ':'Sch','Ь':'','Ы':'Y','Ъ':'','Э':'E','Ю':'Yu','Я':'Ya','a':'a','b':'b','c':'c','d':'d','e':'e','f':'f','g':'g','h':'h','i':'i','j':'j','k':'k','l':'l','m':'m','n':'n','o':'o','p':'p','q':'q','r':'r','s':'s','t':'t','u':'u','v':'v','w':'w','x':'x','y':'y','z':'z','A':'A','B':'B','C':'C','D':'D','E':'E','F':'F','G':'G','H':'H','I':'I','J':'J','K':'K','L':'L','M':'M','N':'N','O':'O','P':'P','Q':'Q','R':'R','S':'S','T':'T','U':'U','V':'V','W':'W','X':'X','Y':'Y','Z':'Z'
    }

    return word.split('').map(function (char) { 
        return a[char] || ""; 
    }).join("");
}

function supportedBookFormat(formats){
    return !formats ? false : !!(formats.filter(format => !format.includes("PDF")).length);
}

export default stripHtml;
export { authorName, booksCountText, logOut, ymReachGoal, authorCountText, shelfCountText, transliterate, supportedBookFormat }