import React, { useState, useEffect, useContext, useRef } from "react";
import './Header.css'
import logo_beebooks from "../../img/logo_beebooks.png"
import { NavLink, useLocation } from 'react-router-dom';
import HeaderBanner from "../HeaderBanner/HeaderBanner";
import { FiSearch } from "react-icons/fi"
import { IoMdClose } from "react-icons/io";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { AppContext } from "../../Hooks/appContext";
import { MdOutlineLogout } from "react-icons/md";
import { logOut } from "../../Functions/Functions";
import SEO from "../SEO/SEO";

function Header({meta}){

    const { userLoggedInCtx, userMsisdnCtx, userImageCtx } = useContext(AppContext);

    const [searchPanelShown, setsearchPanelShown] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const {pathname} = useLocation();

    const [searchPage, setSearchPage] = useState(false);

    const [searchParams] = useSearchParams();

    const defaultTitle = "Читать популярные книги в электронной библиотеке | книги билайн";
    const defaultDescription = "Бестселлеры в электронной онлайн библиотеке. Популярное собрание в книги билайн. Читай больше, плати меньше."

    const pageHeader = useRef();

    let prevScroll = 0;
    let topBarShownHist = true;

    useEffect(()=>{
        // if (pathname === "/search/" || pathname === "/search"){
            // setsearchPanelShown(true);
            // const keyword = searchParams.get("s");
            // setSearchValue(keyword);
        // }
        setSearchPage((pathname === "/search/" || pathname === "/search"));
    },[pathname, searchParams]);

    const navigate = useNavigate();

    function getActiveclass(state){
        return state.isActive ? "active" : "";
    }

    function searchPanelKeyDown(event){
        if(event.key === 'Enter'){
            headerSearchSubmit()
        }
    }

    function updateInputValue(e){
        const val = e.target.value;
        setSearchValue(val);
    }

    function clearSearchInput(){
        setSearchValue("");
        setsearchPanelShown(false);
    }

    function headerSearchSubmit(){
        navigate('/search/?s='+searchValue);
    }

    window.addEventListener('scroll', function(event) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let topBarShown = true;
        topBarShown = ((scrollTop < 114) || (scrollTop < prevScroll));
        if (topBarShownHist !== topBarShown){
            changeTopBarVisibility(topBarShown);
            topBarShownHist = topBarShown;
        }
        prevScroll = scrollTop;
    });   
      
      function changeTopBarVisibility(state){
        const stickyTop = state ? "62px" : "0px";
        if (pageHeader.current){
            pageHeader.current.style.top = stickyTop;
        }
      }

    return (
        <>
            <SEO
                title={meta?.title || defaultTitle}
                description={meta?.description || defaultDescription}
            />            
            <HeaderBanner />
            <div className="page-header" ref={pageHeader}>
                <div className="headerWrapper s-bg">
                    <NavLink to="/"><div className="headerLogo"><img src={logo_beebooks} alt="книги билайн" /></div></NavLink>
                    <div className={"headerMenu" + (searchPanelShown ? " searchPanelStyle" : "")}>
                        <div className={`headerSearchIcon ${searchPage ? "active" : ""}`} onClick={() => setsearchPanelShown(searchPage ? false : !searchPanelShown)}><FiSearch /></div>
                        {searchPanelShown ? (
                            <React.Fragment>
                                <input value={searchValue} onChange={updateInputValue} onKeyDown={searchPanelKeyDown} autoFocus className="headerSearchInput" placeholder="Введите название книги, автора или жанр" />
                                <div onClick={clearSearchInput} className="headerSearchCloseGlyph"><IoMdClose /></div>
                                {/* <div onClick={headerSearchSubmit} className="headerSearchSubmitButton">Искать</div> */}
                                <Button text={"Искать"} onClick={headerSearchSubmit} buttonStyle={"thin ptb-5"}/>
                            </React.Fragment>
                            ) : (
                            <React.Fragment>
                                <NavLink to="/" className={state => getActiveclass(state)}><div className="headerMenuItem">Главная</div></NavLink>
                                <NavLink to="/books" className={state => getActiveclass(state)}><div className="headerMenuItem">Книги</div></NavLink>
                                <NavLink to="/audio" className={state => getActiveclass(state)}><div className="headerMenuItem">Аудиокниги</div></NavLink>
                                <NavLink to="/subsinfo" className={state => getActiveclass(state)}><div className="headerMenuItem">Подписки</div></NavLink>
                                <Button action="url" blank={true} href={`https://beelinebooks-ai-search.ru/app`} text="Нейросеть подскажет книгу" customIcon="medishares" buttonStyle="thin plr-20 ptb-6 violet horizontalIcon" />
                            </React.Fragment>
                        )}
                    </div>
                    <div className="headerLoginItem">
                        {
                            userLoggedInCtx
                            ? (
                                <div className="headerUserData">
                                    <NavLink className="headerUserLink" to="/user/edit">
                                        <div className="headerUserImg">
                                            {userImageCtx ? <img src={userImageCtx} alt="книги билайн"/> : null }
                                        </div>
                                        <div className="headerUserImg">
                                            {userMsisdnCtx}
                                        </div>
                                    </NavLink>
                                    <div className="headerLogout" onClick={logOut}>
                                        <MdOutlineLogout />
                                    </div>
                                </div>
                            ) 
                            : <Button action="modal" modalSrc="authorizationPanel" text="Войти" buttonStyle="thin ptb-5 plr-20 black" />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;