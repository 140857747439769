import React from "react";
import qrCode from "../../../../img/new/qrApp.svg"
import downloadText from "../../../../img/new/qrDecs.svg"
import AppBadge from "../../../AppBadge/AppBadge";
import "./ModalDownloadApp.css";
import QRCode from "react-qr-code";

function ModalDownloadApp(props){
    return (
        <div className="modalDownloadApp">
            <h2>{props?.customTitle ? props?.customTitle : "Скачайте приложение"}</h2>
            <div className="badges">
                <div className="qr-row">
                    <div className="qrWrapper">
                        {props?.customQr ? (
                            <QRCode value={props?.customQr} size={145}/>
                        ) : (
                            <img src={qrCode} alt="приложение книги билайн" />
                        )}
                    </div>
                    <img src={downloadText} alt="приложение книги билайн" />
                </div>
                <div className="badges-row">
                    <AppBadge market="appStore" />
                    <AppBadge market="googlePlay" />
                    <AppBadge market="appGallery" />
                    <AppBadge market="ruStore" />
                </div>                
            </div>
        </div>
    )
}

export default ModalDownloadApp;